import React from 'react';
import styled from 'styled-components';

import { CtaLink } from '../helpers';

import { toTop } from '../../../utils/helpers';


const Footer = styled.section`
  position: fixed;
  z-index: 3;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 20px 0;
  color: white;
  background-color: black;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
`;

const Score = styled.div`
  font-size: 1.66em;
`;

const AlignRight = styled.div`
  text-align: right;
`;

const Results = styled.span`
  display: inline-block;
  margin-right: 25px;
`;


function Scoreboard({ points = {}, numMatches }) {
  return (
    <Footer>
      <Grid className="x-wrapper">
        <div>
          <Score>{points.grade || 0}</Score>
          Karakterpoeng
        </div>
        <div>
          <Score>+ {points.credits || 0}</Score>
          Språk- og realfagspoen
        </div>
        <div>
          <Score>= {points.school || 0}</Score>
          <u>Skolepoeng</u>
        </div>
        <div>
          <Score>+ {points.age || 0}</Score>
          Alderspoeng
        </div>
        <div>
          <Score>+ {points.extra || 0}</Score>
          Tilleggspoeng
        </div>
        <div>
          <Score>= {points.total || 0}</Score>
          <u>Konkurransepoeng</u>
        </div>
      </Grid>
      <AlignRight className="x-wrapper">
        <Results>{numMatches} mulige studier</Results>
        <CtaLink to="/proto/study-selector" onClick={ toTop }>Gå til studielvelgeren</CtaLink>
      </AlignRight>
    </Footer>
  );
}

export default Scoreboard;
