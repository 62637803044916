import React from 'react';


function HistoryTable({ data }) {
  return (
    <table>
      <thead>
        <tr>
          <th>Inntak</th>
          <th>Skolepoeng</th>
          <th>Konk.poeng</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(data).reverse().map((year, i) => (
          <tr key={ i }>
            <td>{year}</td>
            <td>{data[year].first}</td>
            <td>{data[year].ordinary}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default HistoryTable;
