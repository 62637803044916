import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import PropTypes from 'prop-types';

import ExpandableField from '../../expandable-field';
import Input from '../../input/input';

import { getStudyFacets } from '../../../utils/studies';

import { ReactComponent as IconCross } from '../../../assets/cross-small.svg';
import { ReactComponent as IconChevronRight } from '../../../assets/chevron-right.svg';
import { Checkbox } from '@utdanningno/designsystem-react';

import './styles.scss';

const studyFacets = getStudyFacets();

let ts;

export default function Filter({
  match = 0,
  count = 0,
  searchStr = '',
  setSearchStr,
  searchFilter = {},
  setSearchFilter,
  showOnlyMatches,
}) {
  const history = useHistory();
  const location = useLocation();

  // Search string

  const [q, setQ] = useState('');
  useEffect(() => {
    setQ(searchStr);
  }, [searchStr]);

  const onTextChange = value => {
    setQ(value);
    clearTimeout(ts);
    ts = setTimeout(() => {
      setSearchStr(value);

      if (location.pathname !== '/studievelger') {
        history.push({ pathname: '/studievelger' });
      }
    }, 500);
  };

  // Setting and removing filters

  const setFilter = (type, name, value) => {
    const newFilter = { ...searchFilter };
    newFilter[type] = newFilter[type] || {};

    if (value) {
      // Set filter to true
      newFilter[type][name] = value;
    }
    else {
      // Remove filter instead of setting to false
      delete newFilter[type][name];
    }

    if (!Object.keys(newFilter[type]).length) {
      // Delete filters with no values
      delete newFilter[type];
    }
    setSearchFilter(newFilter);
  };

  const removeAllFilters = () => {
    setSearchFilter({});
  };

  // List of active filters
  const activeFilters = [];

  Object.keys(searchFilter).forEach(type => {
    Object.keys(searchFilter[type]).forEach(name => {
      if (searchFilter[type][name]) {
        activeFilters.push([type, name]);
      }
    });
  });

  const goToResults = () => {
    const elSearchResult = document.getElementById('selector-content');
    if (elSearchResult) {
      setTimeout(() => elSearchResult.scrollIntoView(), 100);

      if (location.pathname !== '/studievelger') {
        history.push({ pathname: '/studievelger' });
      }
    }
  };

  return (
    <div className="study-filter">
      <div>
        <Input
          label="Søk etter studie i studievelger"
          type="search"
          name="study-search"
          placeholder="Søk etter studie i studievelger"
          alternative
          labelposition="hidden"
          value={ q }
          className="search-input"
          setValue={ onTextChange }
        />

        {searchStr && (match || (!showOnlyMatches && count)) ? (
          <FilterButton
            className="filter-button--link mt-xs"
            onClick={ goToResults }
            Icon={ IconChevronRight }
          >
            Vis {showOnlyMatches ? match : `${match} av ${count}`} resultater
          </FilterButton>
        ) : null}

        {searchStr && (!count || (showOnlyMatches && !match)) ? (
          <FilterButton
            className="filter-button--link mt-xs"
            onClick={ () => onTextChange('') }
          >
            Søket gav ingen resultat. Nullstill søket
          </FilterButton>
        ) : null}
      </div>
      <br />

      <ExpandableField
        label="Filtrer studiested og studietyper"
        labelOpen="Filtrer studiested og studietyper"
      >
        <div className="grid-split">
          <ExpandableField open label="Studienivå" alternative>
            {studyFacets.studieniva.map((level, i) => (
              <Checkbox
                key={ i }
                label={ level.name }
                name={ `check-1-${i}` }
                checked={ searchFilter.level?.[level.name] }
                onChange={ () =>
                  setFilter(
                    'level',
                    level.name,
                    !searchFilter.level?.[level.name]
                  )
                }
              />
            ))}
          </ExpandableField>

          <ExpandableField open label="Studieform" alternative>
            {studyFacets.studieform.map((type, i) => (
              <Checkbox
                key={ i }
                label={ type.name }
                name={ `check-2-${i}` }
                checked={ searchFilter.type?.[type.name] }
                onChange={ () =>
                  setFilter('type', type.name, !searchFilter.type?.[type.name])
                }
              />
            ))}
          </ExpandableField>

          <ExpandableField open label="Fylke" alternative>
            {studyFacets.fylke.map((loc, i) => (
              <Checkbox
                key={ i }
                label={ loc.name }
                name={ `check-3-${i}` }
                checked={ searchFilter.location?.[loc.name] }
                onChange={ () =>
                  setFilter(
                    'location',
                    loc.name,
                    !searchFilter.location?.[loc.name]
                  )
                }
              />
            ))}
          </ExpandableField>
        </div>
      </ExpandableField>

      {activeFilters.length ? (
        <div className="study-filter__active">
          <h3>Dine aktive filtere</h3>
          <p>Viser kun:</p>
          <div className="filter-button-wrapper">
            {activeFilters.map((f, i) => (
              <FilterButton
                key={ i }
                className="mt-xs"
                onClick={ () => setFilter(f[0], f[1], false) }
              >
                {f[1]}
              </FilterButton>
            ))}
          </div>
          <FilterButton
            className="filter-button--reset mt-lg"
            onClick={ removeAllFilters }
          >
            Nullstill alle filtervalg
          </FilterButton>
        </div>
      ) : null}
    </div>
  );
}

Filter.propTypes = {
  name: PropTypes.string,
};

function FilterButton({ Icon = IconCross, onClick, children, className }) {
  return (
    <button
      type="button"
      className={ ['filter-button', className].join(' ').trim() }
      onClick={ onClick }
    >
      {children} <Icon className="icon" />
    </button>
  );
}
