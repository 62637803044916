import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';


const Nav = styled.nav`
  ul {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  li {
    display: inline-block;
    &:not(:last-child) {
      margin-right: 25px;
    }
  }
  a {
    color:  #0BA87E;
    &.is-active {
      text-decoration: underline;
    }
  }
`;


function MainNav() {
  return (
    <Nav>
      <ul>
        <li><NavLink to="/proto/grade-calculator" activeClassName="is-active">Grade Calculator</NavLink></li>
        <li><NavLink to="/proto/study-selector" activeClassName="is-active">Study Selector</NavLink></li>
      </ul>
    </Nav>
  );
}

export default MainNav;
