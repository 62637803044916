import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as IconCross } from '../../assets/cross-small.svg';

import './styles.scss';


export default function Modal({ title, children, open, setOpen, className }) {

  const classIsOpen = open ? 'is-open' : '';

  function handleClose() {
    setOpen(false);
  }

  function handleCloseOutside(e) {
    if (e.target.classList.contains('modal-wrapper')) {
      setOpen(false);
    }
  }

  useEffect(() => {
    const close = e => {
      if (e.keyCode === 27) {
        setOpen(false);
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, [setOpen]);

  return (
    <div
      onClick={ handleCloseOutside }
      className={ ['modal-wrapper', classIsOpen, className].join(' ').trim() }
    >
      <div className="modal">
        <div className="modal__header">
          {title}
          <button onClick={ handleClose }>
            <span className="visuallyhidden">Lukk</span>
            <IconCross className="icon" />
          </button>
        </div>
        <div className="modal__body">{children}</div>
      </div>
    </div>
  );
}


Modal.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

Modal.defaultProps = {
  title: 'Modal',
};
