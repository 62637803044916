import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Section from '../../section';
import Input from '../../input/input';
import Tooltip from '../../tooltip';
import Infobox from '../../infobox';

import {
  getAdditionalPoints,
  getProgramOptions,
} from '../../../utils/programs';
import { getDismissed, getHelpTexts, setDismissed } from '../../../utils/texts';
import { isYobBefore1989 } from '../../../utils/helpers';
import { Button, Checkbox } from '@utdanningno/designsystem-react';
import { Select } from '@utdanningno/designsystem-react-select';

import './styles.scss';

// Additional point and program selection options
const pointsOptions = getAdditionalPoints();
const studyLines = getProgramOptions(true);

export default function FormAbout({
  yob,
  setYob,
  addPoints = {},
  setAddPoints,
  program,
  chooseProgram,
  submitForm,
}) {
  // Tooltips and help texts
  const ref = useRef();
  const [agePointsTip, setAgePointsTip] = useState('');
  const [addPointsTip, setAddPointsTip] = useState('');

  const [noneOfThisInfo, setNoneOfThisInfo] = useState('');
  const [natureProgInfo, setNatureProgInfo] = useState('');
  const [, setRule235Info] = useState(''); // const [rule235Info, setRule235Info] = useState('');
  const [before1989Info, setBefore1989Info] = useState('');

  useEffect(() => {
    ref.current = true;
    (async () => {
      const txt = await getHelpTexts();
      if (txt && ref.current) {
        setAgePointsTip(txt.alderspoeng || '');
        setAddPointsTip(txt.tilleggspoeng || '');
        setNoneOfThisInfo(txt.ingen_av_disse || '');
        setNatureProgInfo(txt.naturbruk || '');
        setRule235Info(txt.regelen_23_5 || '');
        setBefore1989Info(txt.before_1989 || '');
      }
    })();
    return () => (ref.current = false);
  }, []);

  // Year of birth
  const [yearOfBirth, setYearOfBirth] = useState(yob);

  useEffect(() => {
    if (yob && !yearOfBirth) {
      setYearOfBirth(yob);
    }
  }, [yob, yearOfBirth]);

  useEffect(() => {
    if (parseInt(yearOfBirth) > 1920) {
      return setYob(yearOfBirth);
    }
    setYob('');
  }, [yearOfBirth, setYob]);

  // Born before 1989 info texts
  const onBefore1989InfoClose = () => {
    setDismissed('before_1989', true);
  };
  const isBefore1989 = isYobBefore1989(yearOfBirth);
  const showBefore1989Info = isBefore1989 && !getDismissed('before_1989');

  // Additional Points
  const onAddPointsCheck = (name, value) => {
    const newAddPoints = { ...addPoints };
    newAddPoints[name] = value;
    return setAddPoints?.(newAddPoints);
  };

  // Program (study line)
  const selectedProgram = program
    ? program[1]
      ? `${program[0]}.${program[1]}`
      : program[0]
    : '';

  const onProgramSelect = value => {
    const [p, s] = value.split('.');
    chooseProgram(p, s, true);
    setDismissed('program_choice', false);
  };

  // Program specific info text
  const onProgramInfoClose = () => {
    setDismissed('program_choice', selectedProgram);
  };
  const showProgramInfo = getDismissed('program_choice') !== selectedProgram;

  return (
    <form className="intro-form" action="#" onSubmit={ submitForm }>
      <Section width="narrow">
        <fieldset>
          <legend className="visuallyhidden">Hvilket år er du født?</legend>
          <Tooltip title="Alderspoeng" description={ agePointsTip }>
            <h2 className="legend" aria-hidden="true">
              Hvilket år er du født?
            </h2>
          </Tooltip>
          <Input
            label="Jeg er født i"
            type="number"
            maxLength="4"
            minLength="4"
            size="4"
            name="year-of-birth"
            placeholder="2000"
            value={ yearOfBirth }
            setValue={ setYearOfBirth }
          />

          {showBefore1989Info && (
            <Infobox
              title="Obs!"
              description={ before1989Info }
              onClose={ onBefore1989InfoClose }
            />
          )}
        </fieldset>
      </Section>
      <Section>
        <fieldset>
          <legend className="visuallyhidden">Tilleggspoeng</legend>
          <Tooltip title="Tilleggspoeng" description={ addPointsTip }>
            <h2 className="legend" aria-hidden="true">
              Tilleggspoeng
            </h2>
          </Tooltip>
          <p className="font-bold">Har du:</p>
          <div className="checkbox-container">
            {pointsOptions.map((opt, i) => (
              <Checkbox
                key={ i }
                label={ opt.name }
                name={ opt.id }
                checked={ addPoints[opt.id] }
                onChange={ () => onAddPointsCheck(opt.id, !addPoints[opt.id]) }
              />
            ))}
          </div>
        </fieldset>
      </Section>
      <Section className="bottom-section" width="narrow">
        <fieldset>
          <h2>Hvilken linje går du?</h2>
          <p>
            <label htmlFor="study-lines">
              Velg hvilket utdanningsprogram og program-<br/>område du går eller har
              gått på videregående.
            </label>
          </p>
          <Select
            id="study-lines"
            name="study-lines"
            onChange={ value => onProgramSelect(value.value) }
            defaultValue={ studyLines[0] }
            options={ studyLines }
            required
          />

          {showProgramInfo && selectedProgram === 'NONE' && (
            <Infobox
              title="Obs!"
              description={ noneOfThisInfo }
              onClose={ onProgramInfoClose }
            />
          )}
          {showProgramInfo && selectedProgram === 'NA.NANAB' && (
            <Infobox
              title="Om Vg3 naturbruk"
              description={ natureProgInfo }
              onClose={ onProgramInfoClose }
            />
          )}
          <Button
            className="mt-lg"
            type="submit"
            disabled={ !selectedProgram || selectedProgram === 'NONE' }
            primary
            chevron
          >
            Neste
          </Button>
        </fieldset>
      </Section>
    </form>
  );
}

FormAbout.propTypes = {
  yob: PropTypes.string,
  addPoints: PropTypes.object,
  program: PropTypes.array,
  setYob: PropTypes.func,
  setAddPoints: PropTypes.func,
  setProgram: PropTypes.func,
};

FormAbout.defaultProps = {
  yob: '',
  addPoints: {},
  program: null,
  setYob: () => { },
  setAddPoints: () => { },
  chooseProgram: () => { },
};
