import React from "react";
// import PropTypes from 'prop-types';

/**
 * Chip
 * @arg {object} props
 * @arg {any} props.children - content, `<Chip>Chip text</Chip>`
 * @arg {string} [props.label]
 * @arg {object|object[]|string|number} props.value
 * @arg {function} [props.onClick] - click function for Chip; passed `(value, event)`
 * @arg {boolean} [props.close] - shows close icon; enables Delete/Backspace trigger `onClick`
 * @arg {function} [props.onKeyDown] - additional function after onClick when keyDown Delete/Backspace; passed `(event)`; sets `close`
 * @arg {boolean} [props.dark]
 * @arg {string} [props.className]
 * @arg {object} [props.rest]
 * @return {JSX.Element}
 */

export const Chip = ({
  children,
  label,
  value,
  dark,
  className,
  onClick,
  onKeyDown,
  close,
  ...rest
}) => {
  const classes = [
    `chip`,
    dark ? `chip--dark` : ``,
    close || onKeyDown ? `icons-styled` : ``,
    className,
  ].join(` `);

  /**
   * @param event
   * @param callback
   */
  const onKeyPress = (event, callback) => {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      callback();
    }
  };

  /**
   * onKeyDown decorator
   * executes `onClick` if `close` callback on pressing Delete and Backspace keys
   * Passes Chip value and event
   * @param event
   */
  const handleCloseKeyDown = event => {
    if (close || onKeyDown) onKeyPress(event, () => {
      onClick(value, event);
      if (onKeyDown) onKeyDown(event);
    });
  };

  /**
   * onClick decorator
   * Passes Chip value and event
   * @param event
   */
  const handleClick = event => onClick ? onClick(value, event) : false;

  return (
    <button
      className={classes}
      onClick={handleClick}
      onKeyDown={handleCloseKeyDown}
      {...rest}
    >
      {children || label}

      {(close || onKeyDown) &&
        <svg aria-hidden="true" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.43222 1.34355L16.6322 16.4436" stroke="#333333" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M16.6322 1.34355L1.43222 16.4436" stroke="#333333" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      }
    </button>
  );
};

// Chip.propTypes = {
//   value: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string, PropTypes.number]).isRequired,
//   label: PropTypes.string,
//   dark: PropTypes.bool,
//   closeButtonLabel: PropTypes.string,
//   onClick: PropTypes.func,
//   onClose: PropTypes.func,
//   onKeyDown: PropTypes.func,
// };

// Chip.defaultProps = {
//   value: undefined,
//   label: undefined,
//   dark: false,
//   closeButtonLabel: 'Close',
//   onClick: () => {
//   },
//   onClose: () => {
//   },
//   onKeyDown: () => {
//   },
// };

export default Chip;
