import React, { forwardRef } from 'react';
// import PropTypes from 'prop-types';

export const Button = forwardRef((
  {
    children,
    label,
    primary,
    chevron,
    className,
    href,
    icon,
    ...rest
  },
  ref
) => {
  const ButtonTag = typeof href === 'string' ? 'a' : 'button';
  const hasIcon = React.isValidElement(icon);
  const classes = [
    `button`,
    primary ? `button--primary` : ``,
    chevron ? `button--chevron-prop` : ``,
    className,
  ].join(' ');

  return (
    <ButtonTag ref={ref} href={href} className={classes} {...rest}>
      {hasIcon &&
        <span className="icon" aria-hidden="true">{icon}</span>
      }

      <span>{children || label || ``}</span>

      {chevron &&
        <span className="icon icon--chevron-right" aria-hidden="true">
          <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.45001 13.1L6.55001 8L1.45001 2.9" stroke="#333333" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </span>
      }
    </ButtonTag>
  );
});

// Button.propTypes = {
//   primary: PropTypes.bool,
//   chevron: PropTypes.bool,
//   className: PropTypes.string,
//   href: PropTypes.string,
//   icon: PropTypes.element,
//   label: PropTypes.string,
//   children: PropTypes.any,
// };
//
// Button.defaultProps = {
//   primary: false,
//   chevron: false,
//   className: '',
//   href: undefined,
//   icon: undefined,
//   label: undefined,
//   children: undefined,
// };

export default Button;
