import React from 'react';
import styled from 'styled-components';

import PointsList from './score/points-list';
import { GoodPassFail, PassFail, Participated } from './score/multiple-option';

import { getSubjects } from '../../../utils/subjects';


const allSubjects = getSubjects();

const SCORE_COMPS = {
  1: PointsList,
  2: PointsList,
  3: Participated,
  4: PassFail,
  5: GoodPassFail,
};


const PaddedTable = styled.table`
  margin: 20px 0 40px;
`;

const SelectLabel = styled.span`
  display: inline-block;
  margin-right: 15px;
  font-weight: 700;
`;


function _sort(a, b) {
  return b.name < a.name ? 1 : -1;
}


function SubjectTable({
  title,
  items = {},
  specialItems = {},
  languageItem,
  section,
  removeSubject,
  chooseSubject,
  setScore,
}) {
  if (!Object.keys(items).length && !Object.keys(specialItems).length && !languageItem) {
    return null;
  }

  const onRemove = (ev, sect, id) => {
    ev.preventDefault();
    return removeSubject?.(sect, id);
  };

  const onChooseSubject = (ev, sect, id) => {
    const value = ev.target.value;
    return chooseSubject?.(sect, id, value);
  };

  const actions = { onRemove, onChooseSubject, setScore };

  return (
    <PaddedTable>
      <thead>
        <tr>
          <th>Fjern fag</th>
          <th colSpan={ 2 }>{title}</th>
          <th>Standpunktkarakter</th>
          <th>Eksamenskarakter</th>
        </tr>
      </thead>
      <tbody>
        <SubjectRows items={ items } section={ section } { ...actions } />
        <SpecialRows items={ specialItems } { ...actions } />
        <LanguageRow item={ languageItem } { ...actions } />
      </tbody>
    </PaddedTable>
  );
}


function SubjectScore({ subject, ...props }) {
  const C = SCORE_COMPS[subject.type];
  return C && <C subject={ subject.id } { ...props } />;
}


function SubjectRows({ items, section, onRemove, setScore }) {
  const list = Object.keys(items).map(s => allSubjects[s]).sort(_sort);
  return list.map(s => (
    <tr key={ s.id }>
      <td>
        <a href="." onClick={ ev => onRemove(ev, section, s.id) }>X</a>
        <span style={ { marginLeft: '15px' } }>{s.id}</span>
      </td>
      <td colSpan={ 2 }>
        <strong>{s.name}</strong>
      </td>
      <td>
        {s.position ?
          <SubjectScore
            section={ section } subject={ s } type="position"
            value={ items[s.id] && items[s.id][0] } setScore={ setScore }
          />
          : null}
      </td>
      <td>
        {s.exam ?
          <SubjectScore
            section={ section } subject={ s } type="exam"
            value={ items[s.id] && items[s.id][1] } setScore={ setScore }
          />
          : null}
      </td>
    </tr>
  ));
}


function SpecialRows({ items, onRemove, onChooseSubject, setScore }) {
  const list = Object.keys(items).map(id => {
    const { name, subjects, selected, value } = items[id];
    return {
      id, name, selected, value,
      options: subjects.map(s => allSubjects[s]),
    };
  });

  return list.map(s => (
    <tr key={ s.id }>
      <td>
        <a href="." onClick={ ev => onRemove(ev, 'special', s.id) }>X</a>
      </td>
      <td colSpan={ 2 }>
        <SelectLabel>{s.name}</SelectLabel>
        <select
          value={ s.selected || '' }
          onChange={ ev => onChooseSubject(ev, 'special', s.id) }
        >
          <option value="">Velg</option>
          {s.options?.map(opt => (
            <option key={ opt.id } value={ opt.id }>{opt.name?.substr(11)}</option>
          ))}
        </select>
      </td>
      <td>
        {s.selected ?
          <PointsList
            section="special" subject={ s.id } type="position"
            value={ s.value && s.value[0] } setScore={ setScore } />
          : null}
      </td>
      <td>
        {s.selected ?
          <PointsList
            section="special" subject={ s.id } type="exam"
            value={ s.value && s.value[1] } setScore={ setScore } />
          : null}
      </td>
    </tr>
  ));
}


function LanguageRow({ item, onRemove, onChooseSubject, setScore }) {
  return item ?
    <tr>
      <td>
        <a href="." onClick={ ev => onRemove(ev, 'language') }>X</a>
      </td>
      <td colSpan={ 2 }>
        <SelectLabel>{item.name}</SelectLabel>
        <select
          value={ item.selected || '' }
          onChange={ ev => onChooseSubject(ev, 'language') }
        >
          <option value="">Velg</option>
          {item.subjects?.map((lang, idx) => (
            <option key={ idx } value={ lang }>{lang}</option>
          ))}
        </select>
      </td>
      <td>
        {item.selected ?
          <PointsList
            section="language" subject={ null } type="position"
            value={ item.value && item.value[0] } setScore={ setScore }
          />
          : null}
      </td>
      <td>
        {item.selected ?
          <PointsList
            section="language" subject={ null } type="exam"
            value={ item.value && item.value[1] } setScore={ setScore }
          />
          : null}
      </td>
    </tr>
    : null;
}


export default SubjectTable;
