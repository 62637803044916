/**
 * Programs
 * @module utils/programs
 */

import allPrograms from '../data/programs.json';
import allAddPoints from '../data/additional-points.json';


/**
 * Returns a structure with all the programs available.
 * The list of programs is retrieved from the utdanning.no API.
 * Source: https://api.utdanning.no/karakterkalkulator/points?filename=fag_built.json
 *
 * @returns {object} a key-value object with all the programs available
 */
export function getPrograms() {
  return allPrograms;
}

/** 
 * Sorts an array of "linjevalg".
 * First "Studiespesialisering"
 * Then "Musikk, dans og drama"
 * Then those that starts with a letter
 * Then those that does not start with a letter (23/5 is the only one now)
 * Standard sort within those categories
**/

function linjeSort(a, b) {
  const aLabel = a.label || '';
  const aValue = a.value || '';
  const aValueFirstChar = aValue.substring(0, 1);
  const bLabel = b.label || '';
  const bValue = b.value || '';
  const bValueFirstChar = bValue.substring(0, 1);
  if (aLabel === 'Studiespesialisering') {
    return -1;
  }
  if (bLabel === 'Studiespesialisering') {
    return 1;
  }
  if (aLabel === 'Musikk, dans og drama') {
    return -1;
  }
  if (bLabel === 'Musikk, dans og drama') {
    return 1;
  }
  if (isNaN(aValueFirstChar) && !isNaN(bValueFirstChar)) {
    return -1;
  }
  if (!isNaN(aValueFirstChar) && isNaN(bValueFirstChar)) {
    return 1;
  }
  return a.label > b.label ? 1 : -1;
}

/**
 * Returns list of programs options.
 * It is structured to be used directly in a selected box.
 * Each of the programs can have another list of children programs.
 * If called from the intro form, an option for the "none of this" should be added.
 *
 * @param {boolean} isIntroForm indicates if the list is used on the intro form
 * @returns {array} A list of programs names and ids
 */
export function getProgramOptions(isIntroForm) {
  const res = [];
  // if(isIntroForm) {
  //   // Add empty option
  //   res.push({ label: 'Velg linje', value: '' });
  // }

  Object.keys(allPrograms).forEach(k1 => {
    const prog = allPrograms[k1];
    if (prog.skip) {
      res.push({
        label: prog.name,
        value: `${prog.id}.${prog.skip}`,
      });
    }
    else {
      const option = {
        label: prog.name,
        options: [],
      };
      Object.keys(prog.programs).forEach(k2 => {
        const sub = prog.programs[k2];
        option.options.push({
          label: sub.name,
          value: `${prog.id}.${sub.id}`,
        });
      });
      res.push(option);
    }
  });
  res.sort(linjeSort);
  if (isIntroForm) {
    // Add empty first option
    res.unshift({ label: 'Velg linje', value: '' });
    // Add none option
    res.push({ label: 'Jeg har ingen av disse', value: 'NONE' });
  }

  return res;
}

/**
 * Returns a object with the additional points and the values for each.
 *
 * @returns {object} a key-value object with additional points
 */
export function getAdditionalPoints() {
  const res = Object.keys(allAddPoints).map(k => ({
    id: k,
    ...allAddPoints[k],
  }));
  return res;
}
