import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as IconChevron } from '../../assets/chevron-down.svg';

import './styles.scss';

export default function Accordion({ title, open, children, className }) {
  const [isOpen, setIsOpen] = useState(open || false);

  const classIsOpen = isOpen ? 'is-open' : '';

  function handleToggle() {
    setIsOpen(!isOpen);
  }

  return (
    <div className={ ['accordion', classIsOpen, className].join(' ').trim() }>
      <button onClick={ handleToggle } className="accordion__header">
        <span className="accordion__title">{title}</span>
        <IconChevron className="icon" />
      </button>
      {isOpen && (
        <div
          className="accordion__body"
          dangerouslySetInnerHTML={ { __html: children } }
        />
      )}
    </div>
  );
}

Accordion.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
};

Accordion.defaultProps = {
  title: 'Accordion title',
};
