import React from "react";
import TextInput from "../text-input/text-input";
import PropTypes from 'prop-types';
import {Tooltip} from "../tooltip/tooltip.js";

/**
 * @param id
 * @param name
 * @param type
 * @param label
 * @param isInvalid
 * @param errorMessage
 * @param helpText
 * @param className
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
export function InputField({
                             id,
                             name,
                             type,
                             label,
                             placeholder,
                             isInvalid,
                             errorMessage,
                             tooltipHeading,
                             tooltipText,
                             className,
                             ...rest
                           }) {

  const classes = [
    'input-field',
    className,
  ].join(` `);

  const tooltipID = `${id}-tooltip`;
  const descriptionID = `${tooltipID}-description`;

  return (
    <div className={classes}>

      {
        typeof label === 'string' &&

        <div className={'input-field__label'}>
          <span>{label}</span>
          {
            (typeof tooltipHeading === 'string' || typeof tooltipText === 'string') &&
            <div className={'input-field__tooltip'}>
              <Tooltip id={tooltipID} heading={tooltipHeading} content={tooltipText}/>
            </div>
          }
        </div>
      }

      <TextInput
        id={id}
        name={name}
        type={type}
        isInvalid={isInvalid}
        placeholder={placeholder}
        aria-label={label || placeholder}
        aria-describedby={descriptionID}
        {...rest}
      />
      {
        isInvalid && typeof errorMessage === 'string' &&

        <div className={'input-field__error'}>
          <span>{errorMessage}</span>
        </div>
      }
    </div>
  );
}

InputField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  tooltipHeading: PropTypes.string,
  tooltipText: PropTypes.string,
  isInvalid: PropTypes.bool,
  className: PropTypes.string,
};

InputField.defaultProps = {
  id: undefined,
  name: undefined,
  label: undefined,
  placeholder: undefined,
  errorMessage: undefined,
  tooltipHeading: undefined,
  tooltipText: undefined,
  isInvalid: false,
};

export default InputField;
