import { Route, Switch } from 'react-router-dom';

import StudySelector from './study-selector';
import GradeCalculator from './grade-calculator';
import Prototype from './prototype';

import useGoogleAnalytics from '../utils/useGoogleAnalytics';

export default function Routes({ data }) {

  // Routes data
  const { state1, state2, actions, showFAQ } = data;

  // Enable google analytics (ga4)
  useGoogleAnalytics();

  return (
    <Switch>
      <Route path="/proto">
        <Prototype state1={ state1 } state2={ state2 } actions={ actions } />
      </Route>
      <Route path="/studievelger">
        <StudySelector { ...state2 } showFAQ={ showFAQ } />
      </Route>
      <Route exact path="/">
        <GradeCalculator { ...state1 } { ...actions } />
      </Route>
    </Switch>
  );
}
