import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Button } from '@utdanningno/designsystem-react';
import { ReactComponent as QuestionIcon } from '../../assets/question-circle.svg';

import './styles.scss';

export default function NavTabs({ tabs, showFAQ, ...props }) {
  return (
    <nav className="nav-tabs" { ...props }>
      <h2 className="visuallyhidden">Velg område</h2>
      <ul>
        {tabs.map(({ to, name, exact }) => (
          <li className="nav-tabs__tab" key={ name }>
            <NavLink to={ to } exact={ exact } activeClassName="is-active">
              {name}
            </NavLink>
          </li>
        ))}
      </ul>
      <Button primary onClick={ showFAQ } className="faq-button"><QuestionIcon className="faq-icon" /><span className="faq-label">Ofte stilte spørsmål</span></Button>
    </nav>
  );
}

NavTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      exact: PropTypes.bool,
    }),
  ),
};


NavTabs.defaultProps = {
  tabs: [
    { to: '/', name: 'Karakterkalkulatoren', exact: true },
    { to: '/studievelger', name: 'Studievelger', exact: false },
  ],
};
