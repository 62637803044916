import React from 'react';

import { getHistoricalData } from '../../../../utils/studies';


// const getDiff = (field, curr, next) => {
//   if(!next) {
//     return '';
//   }
//   const x = parseFloat(curr[field]) || 0;
//   const y = parseFloat(next[field]) || 0;
//   return x > y ? 'study__up' : (x < y ? 'study__down' : '');
// };


export default function HistoryTable({ studyId }) {

  const history = getHistoricalData(studyId);
  if (!history) {
    return null;
  }
  const list = Object.keys(history).reverse().map(y => ({ year: y, ...history[y] }));

  return (
    <>
      <h4 className="study__title-2 mt-lg">Historiske poenggrenser</h4>
      <table>
        <thead>
          <tr>
            <th>Inntak</th>
            <th>Skolepoeng</th>
            <th>Konkurransepoeng</th>
          </tr>
        </thead>
        <tbody>
          {list.map((item, i) => {
            // const next = list[i + 1];
            return (
              <tr key={ i }>
                <td>{item.year}</td>
                {/* <td><span className={ getDiff('first', item, next) }>{ item.first || 'Alle' }</span></td>
                <td><span className={ getDiff('ordinary', item, next) }>{ item.ordinary || 'Alle' }</span></td> */}
                <td>{item.first || 'Alle'}</td>
                <td>{item.ordinary || 'Alle'}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
