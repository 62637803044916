import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ExpandableField from '../../components/expandable-field';
import Input from '../../components/input/input';

import { ReactComponent as IconChange } from '../../assets/change.svg';

import './styles.scss';


export default function EditPoints({ points, setPoints, resetPoints, compact, above22, className }) {
  const [showSchoolRagne, setShowSchoolRange] = useState(true);

  function handleToggle() {
    setShowSchoolRange(!showSchoolRagne);
  }

  const pointsDiff = (points.age || 0) + (points.extra || 0);
  const maxSchool = 64;
  const maxPoints = pointsDiff + maxSchool;

  const onPointChange = (type, value) => {
    const newPoints = { ...points };
    let val = parseFloat(value) || 0;

    if (type === 'school') {
      if (val > maxSchool) { val = maxSchool; }
      if (val < 0) { val = 0; }

      newPoints.school = val;
      newPoints.total = val + pointsDiff;
    }
    else {
      if (val > maxPoints) { val = maxPoints; }
      if (val < pointsDiff) { val = pointsDiff; }

      newPoints.total = val;
      newPoints.school = val - pointsDiff;
    }
    setPoints(newPoints);
  };

  const classCompact = compact ? 'edit-points--compact' : '';

  return (
    <div className={ ['edit-points', classCompact, className].join(' ') }>
      {compact ? (
        <ExpandableField label="Rediger dine poeng manuelt" labelOpen="Rediger dine poeng manuelt">
          {showSchoolRagne && !above22 ? (
            <>
              <RangeSchool points={ points } setPoints={ onPointChange } maxPoints={ maxPoints } />
              <button type="button" onClick={ handleToggle }>
                Bytt til konkurransepoeng
                <IconChange className="icon" />
              </button>
              <p>Rediger konkurransepoeng.</p>
            </>
          ) : (
            <>
              <RangeCompetition points={ points } setPoints={ onPointChange } maxPoints={ maxPoints } />
              {!above22 &&
                <>
                  <button type="button" onClick={ handleToggle }>
                    Bytt til skolepoeng
                    <IconChange className="icon" />
                  </button>
                  <p>Rediger skolepoeng.</p>
                </>}
            </>
          )}
          <Reset className="mt-lg" resetPoints={ resetPoints } />
        </ExpandableField>
      ) : (
        <>
          <h3>Rediger dine poeng manuelt</h3>
          {!above22 &&
            <RangeSchool points={ points } setPoints={ onPointChange } maxPoints={ maxPoints } />
          }
          <RangeCompetition
            className="mt-md"
            points={ points } setPoints={ onPointChange } maxPoints={ maxPoints }
          />
          <Reset className="mt-md" resetPoints={ resetPoints } />
        </>
      )}
    </div>
  );
}

function RangeSchool({ points, setPoints, maxPoints = 74, ...props }) {
  return (
    <Input
      label="Skolepoeng"
      labelposition="above"
      name="school-points"
      type="range"
      min="0" max={ maxPoints } step="1"
      value={ points?.school || 0 }
      setValue={ value => setPoints('school', value) }
      { ...props }
    />
  );
}

function RangeCompetition({ points, setPoints, maxPoints = 74, ...props }) {
  return (
    <Input
      id="competition-points"
      label="Konkurransepoeng"
      labelposition="above"
      name="school-points"
      type="range"
      min="0" max={ maxPoints } step="1"
      value={ points?.total || 0 }
      setValue={ value => setPoints('total', value) }
      { ...props }
    />
  );
}

function Reset({ className, resetPoints }) {
  return (
    <>
      <button
        type="button"
        className={ ['edit-points__reset', className].join(' ').trim() }
        onClick={ resetPoints }
      >
        Tilbakestill poeng
      </button>
      <p>Tilbakestiller til poengene dine fra Karakterkalkulatoren.</p>
    </>
  );
}

// function InputScale() {
//   return (
//     <div>
//       <label htmlFor="score"></label>
//       <input type="range" min="0" max="60" id="score" name="score" />
//     </div>
//   );
// };


EditPoints.propTypes = {
  points: PropTypes.object,
};

EditPoints.defaultProps = {
  points: {},
};
