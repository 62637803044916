/**
 * Subjects
 * @module utils/subjects
 */

import allSubjects from '../data/subjects.json';


/**
 * Returns a structure with all the subjects
 *
 * @returns {object} a key-value object with all the subjects by id
 */
export function getSubjects() {
  return allSubjects;
}


/**
 * Searches the subjects for a given string.
 * Checks for matches in the subject ID and name.
 *
 * @param {string} str the input string
 * @returns {array} the list of results
 */
export function searchSubjects(str) {
  const xstr = str.toLowerCase();
  return Object.values(allSubjects)
  .filter(s => s.id.toLowerCase().includes(xstr) || s.name.toLowerCase().includes(xstr));
}


/**
 * Returns a object that represents the language subject.
 * It follows the same structure as all other subjects.
 * It is used in the subject map for representing the language options.
 *
 * @returns {object} the language subject
 */
export function getLanguageSubject() {
  return {
    id: 'LANG',
    name: 'Fremmedspråk',
    position: 1, exam: 1, type: 1,
    section: 'language',
  };
}


/**
 * Returns a list of languages.
 * It is generated from the languages available on program.
 * The structure is meant to be used directly in a select box.
 *
 * @param {array} list the list of languages available
 * @returns {array} the language options array
 */
export function getLanguageOptions(list) {
  return [
    { label: 'Velg', value: '' },
    ...list.map(str => ({ label: str, value: str })),
  ];
}


/**
 * Returns a representation of a special subject.
 * It follows the same structure as all other subjects.
 * It is used in the subject map for representing the special subject options.
 *
 * @param {object} subj the special subject (in the subject map)
 * @returns {object} the special subject full structure
 */
export function getSpecialSubject({ id, name, selected }) {
  const subj = selected && allSubjects[selected];
  return {
    id, name,
    position: subj ? subj.position : 1,
    exam: subj ? subj.exam : 1,
    type: subj ? subj.type : 1,
    section: 'special',
  };
}


/**
 * Returns a list of subjects that can be selected for a special subject.
 * It is generated from the subjects available for the special subject.
 * The structure is meant to be used directly in a select box.
 *
 * @param {array} list the list of subjects available
 * @returns {array} the subject options array
 */
export function getSpecialOptions(list) {
  return [
    { label: 'Velg', value: '' },
    ...list.map(id => ({ label: allSubjects[id]?.name || id, value: id })),
  ];
}

function getSubjectParent(availableSubjects, parentId) {
  const parent = availableSubjects[parentId];
  if (parent?.replacedBy) {
    for (const pId of parent.replacedBy) {
      getSubjectParent(availableSubjects, pId);
    }
  }
  return parent?.id;
}

function getSubjectParents(availableSubjects, subject) {
  if (subject.replacedBy) {
    const parentIds = subject.replacedBy;

    const parents = parentIds.map(parentId => {
      return getSubjectParent(availableSubjects, parentId);
    });
    return parents.filter(p => p);
  }

  return [];
}

/**
 * Groups all the subjects based on replacedBy and returns array of subjects with all the children.
 * Children is determined by replacedBy key in subjects.json
 * @param {array} subjects 
 * @returns {array} grouped subjects with children
 */
export function getGroupedSubjects(subjects) {
  const availableSubjects = subjects.reduce((a, b) => {
    a = { ...a, [b.id]: { ...b } };

    return a;
  }, {});

  // get all subject parents
  const subjectWithParents = subjects.map(subject => {
    return {
      ...subject,
      parents: getSubjectParents(availableSubjects, subject),
    };
  });

  // group subjects by parent
  let groupedSubjects = {};

  subjectWithParents.forEach(subject => {
    if (subject.parents.length) {
      subject.parents.forEach(parent => {
        if (groupedSubjects[parent]) {
          groupedSubjects[parent] = {
            ...groupedSubjects[parent],
            children: groupedSubjects[parent].children
              ? [...groupedSubjects[parent].children, subject]
              : [subject],
          };
        }
        else {
          groupedSubjects = {
            ...groupedSubjects,
            [parent]: { ...availableSubjects[parent], children: [subject] },
          };
        };
      });
    }
    else {
      groupedSubjects = groupedSubjects[subject.id]
        ? groupedSubjects
        : { ...groupedSubjects, [subject.id]: subject };
    }
  });

  groupedSubjects = Object.keys(groupedSubjects).map(key => {
    return groupedSubjects[key];
  });

  return groupedSubjects;
}

export const getSubjectCode = (options, selected, id, children) => {
  if (options) { return `[${selected || ' '}]`; }
  if (!children) { return `[${id}]`; }

  const childrenIds = children?.map(
    (child, idx) => `[${child.id}] ${idx < children.length - 1 ? '/' : ''} `
  );

  return `[${id}] / ${childrenIds.join('')}`;
};
