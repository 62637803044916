import React, { useEffect, useState } from 'react';

import { Button } from '../helpers';
import { StudyTableInner } from './study-table';

import { searchStudies } from '../../../utils/studies';


function StudySearchResults({ query, requirements, points }) {
  const [list, setList] = useState([]);
  const [rows, setRows] = useState(0);

  useEffect(() => {
    setRows(10);
    setList(searchStudies(query));
  }, [query]);

  const loadMore = () => {
    setRows(rows + 10);
  };

  const subList = list.slice(0, rows);

  return (
    <div>
      <h2><u>Søk: {query}</u></h2>
      <span>{list.length} studier</span>
      {list.length
        ? <div>
          <StudyTableInner list={ subList } requirements={ requirements } points={ points } />
          {rows < list.length
            ? <Button onClick={ loadMore }>Se flere</Button>
            : null}
        </div>
        : null}
    </div>
  );
}

export default StudySearchResults;
