/**
 * Study requirements compiler
 *
 * @module compiler/subject-requirements
 * @description
 * Requirement logic:
 * https://api.utdanning.no/karakterkalkulator/points?filename=UHG_forenklet_kravkodelogikk_redusert.json
 *
 * Requirements (calculated and described):
 * https://api.utdanning.no/karakterkalkulator/points?filename=kravelementer.json
 *
 * @see module:compiler/subject-requirements/frontend
 * @see module:compiler/subject-requirements/backend
 *
 */

import rules from '../../data/subject-logic.json';
import requirements from '../../data/requirements.json';

import { compile } from './frontend';
import { generator } from './backend';


// Compile the logic rules
const compiled = compile(rules);
// console.log('Subject requirements compiled:', compiled);



/**
 * Evaluates calculated requirements given the grades and points.
 *
 * First it combines the subject scores into a flat object. Then evaluates each requirement.
 *
 * @param {object} subjectMap the subject map with grades per section
 * @param {object} points the total points result
 * @returns {object} the calculated requirements
 */
export function calculateRequirements(subjectMap, points) {
  const schoolPoints = points.school;
  const subjectScores = {};

  // Flatten the subjectMap to a table of subject scores
  Object.keys(subjectMap).forEach(section => {
    if (section === 'language') {
      return;
    }
    if (section === 'special') {
      Object.keys(subjectMap.special).forEach(subj => {
        const option = subjectMap.special[subj];
        if (option.value && option.score) {
          subjectScores[option.value] = option.score;
        }
      });
      return;
    }
    Object.keys(subjectMap[section]).forEach(subj => {
      if (subjectMap[section][subj]) {
        subjectScores[subj] = subjectMap[section][subj];
      }
    });
  });

  // Go through the list of requirements and evaluate each one
  const res = {};
  Object.keys(compiled).forEach(req => {
    try {
      res[req] = generator(compiled[req])({ subjectScores, schoolPoints });
    }
    catch (err) {
      console.log('Error evaluating requirement:', req);
      console.error(err);
      res[req] = null;
    }
  });
  return res;
}


/**
 * Returns a key-value object with the described requirements.
 * Since it's not possible to determine these requiremens, they are all set to null,
 *
 * @returns {object} the described requirements
 */
export function getDescribedRequirements() {
  const { described } = requirements;

  const res = {};
  Object.keys(described).forEach(k => res[k] = null);

  return res;
}
