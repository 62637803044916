import React from "react";
import Input from '../../elements/input';
import PropTypes from 'prop-types';

/**
 * @param searchButtonLabel
 * @param onSubmit
 * @param className
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
export const SearchField = ({
  searchButtonLabel = `Søk`,
  className,
  onSubmit = () => 0,
  ...rest
}) => {

  const classes = [
    `search-wrapper`,
    typeof className === 'string' ? className : ``,
  ].join(` `);

  return (
    <form className={classes} onSubmit={onSubmit}>
      <Input
        type="search"
        placeholder="Søk"
        {...rest}
      />
      <button
        title={searchButtonLabel}
        type="submit"
      >
        <svg aria-hidden="true" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.7 13.9C11.1242 13.9 13.9 11.1242 13.9 7.7C13.9 4.27583 11.1242 1.5 7.7 1.5C4.27584 1.5 1.5 4.27583 1.5 7.7C1.5 11.1242 4.27584 13.9 7.7 13.9Z" stroke="#333333" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M12.1 12.1L16.5 16.5" stroke="#333333" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </button>
    </form>
  );
}

SearchField.propTypes = {
  searchButtonLabel: PropTypes.string,
  className: PropTypes.string,
  onSubmit: PropTypes.func,
};

SearchField.defaultProps = {
  searchButtonLabel: `Søk`,
  onSubmit: () => 0,
};

export default SearchField;
