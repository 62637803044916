import React from 'react';
import styled from 'styled-components';

import addPoints from '../../../data/additional-points.json';


const Block = styled.div`
  margin-bottom: 30px;

  input, select, span {
    margin-right: 10px;
  }
`;


function AdditionalPoints({ values = {}, setValues, yob = '', setYob }) {
  const year = new Date().getFullYear();
  const years = [];
  for (let i = year - 15; i >= 1920; i--) {
    years.push(i);
  }

  const onSelectedYob = ev => {
    return setYob?.(ev.target.value);
  };

  const onCheckboxChange = (ev, field) => {
    const newValues = { ...values };
    newValues[field] = ev.target.checked;
    return setValues?.(newValues);
  };

  const options = Object.keys(addPoints).map(k => ({
    ...addPoints[k],
    id: k,
  }));

  return (
    <section>
      <Block>
        <h2>Hvilket år er du født?</h2>
        <span>Jeg er født i</span>
        <select
          value={ yob }
          onChange={ onSelectedYob }
        >
          <option value="">Velg</option>
          {years.map(y => <option key={ y } value={ y }>{y}</option>)}
        </select>
      </Block>
      <Block>
        <h2>Tillegspoeng</h2>
        <div>Har du</div>
        {options.map((opt, idx) => (
          <div key={ idx }>
            <input
              type="checkbox" id={ `add-${opt.id}` }
              checked={ values[opt.id] || false }
              onChange={ ev => onCheckboxChange(ev, opt.id) }
            />
            <label htmlFor={ `add-${opt.id}` }>{opt.name}</label>
          </div>
        ))}
      </Block>
    </section>
  );
}

export default AdditionalPoints;
