/**
 * Subject requirements compiler frontend
 * @module compiler/subject-requirements/frontend
 */

import '../helpers';


// Operators and other reserved words
const operators = [
  'ogf', 'og', 'ellerf', 'eller', 'ikke_',
];

const groups = [
  'alle(', ').snttk', '(', ')',
];

// Language variables
const variables = [
  '[A-ZÆØÅ]{3,5}[0-9x]{2,4}',
  'SKOLEPOENG',
  'omfang>[0-9]+',
  '1=1',
];


// Compiler frontend


/**
 * Takes a language token, identifies it and returns metadata about it
 *
 * @param {str} str the token
 * @returns {object} the token with metadata
 */
function getToken(str) {
  if (operators.includes(str)) {
    return {
      type: 'OP',
      value: str,
    };
  }
  if (groups.includes(str)) {
    return {
      type: 'GRP',
      value: str,
    };
  }
  for (const exp of variables) {
    if (str.match(new RegExp(`^${exp}$`, 'ig'))) {
      return {
        type: 'VAR',
        value: str,
      };
    }
  }
  return {
    type: null,
    value: str,
  };
}


/**
 * Breaks down the program code into a set of tokens
 *
 * @param {string} code the program code
 * @returns {array} the list of tokens
 */
export function tokenizer(code) {
  const escaped = [...operators, ...groups].map(op => op.replace(/([().])/g, '\\$1'));

  const lang = [...escaped, ...variables].map(k => `(${k})`).join('|');
  const regex = new RegExp(lang, 'gi');
  const tokens = code.split(regex).map(t => t && t.trim()).filter(t => t);

  return tokens.map(getToken);
}


/**
 * Cleans up a node before returning it.
 * Removes unnecessary and circular attributes.
 *
 * @param {object} node the tree node
 * @returns {object} the clean node
 */
function cleanTree(node) {
  if (node) {
    delete node.parent;
    if (node.children) {
      node.children = node.children && node.children.map(child => cleanTree(child));
    }
  }
  return node;
}


/**
 * Takes a list of tokens and generates a binary parse tree
 * according to the language rules.
 *
 * @param {array} tokens the list of tokens
 * @returns {object} the parser tree
 */
export function parser(tokens) {
  let cur;

  tokens.forEach((token, i) => {
    const next = tokens[i + 1];

    if (!token.type) {
      throw new Error(`Unkown token: ${token.value}`);
    }

    // Variables
    if (token.type === 'VAR') {
      const node = {
        type: 'VAR',
        value: token.value,
      };
      if (token.neg) {
        node.negate = true;
      }

      if (cur) {
        if (cur.type === 'VAR') {
          throw new Error('Two vars in a row');
        }
        cur.children.push(node);
        node.parent = cur;
      }
      if (!cur || cur.type !== 'OP') {
        cur = node;
      }
      return;
    }

    // Logical operators
    if (token.type === 'OP') {
      if (token.value === 'ikke_') {
        if (!next || next.type !== 'VAR') {
          throw new Error('Negation only applies to variables');
        }
        next.neg = true;
        return;
      }

      if (!next || !cur) {
        throw new Error('Lonely operator');
      }

      const node = {
        type: 'OP',
        value: token.value,
        children: [],
      };

      if (next.type === 'OP' && next.value !== 'ikke_') {
        throw new Error('Two operators in a row');
      }

      if (cur.parent) {
        cur.parent.children.pull(cur);
        cur.parent.children.push(node);
        node.parent = cur.parent;
      }
      node.children.push(cur);
      cur.parent = node;
      cur = node;
      return;
    }

    // Groups
    if (token.type === 'GRP') {
      if (token.value === '(' || token.value === 'alle(') {
        const node = {
          type: 'GRP',
          value: token.value === 'alle(' ? 'all' : 'parentheses',
          children: [],
        };

        if (cur) {
          if (!cur.children) {
            throw new Error('Cannot open parentheses after variable');
          }
          cur.children.push(node);
          node.parent = cur;
        }
        cur = node;
        return;
      }
      if (token.value === ')' || token.value === ').snttk') {
        const grp = cur.parent;

        if (!grp || grp.type !== 'GRP') {
          throw new Error('Invalid parenthesis');
        }
        if (!grp.children || !grp.children.length) {
          throw new Error('Empty parenthesis');
        }

        if (token.value === ').snttk') {
          grp.value = 'average';
        }
        if (grp.parent && grp.parent.type === 'OP') {
          cur = grp.parent;
        }
        else {
          cur = grp;
        }
      }
    }
  });

  // Check that the tree is valid
  if (cur.parent) {
    throw new Error('Invalid tree');
  }
  return cleanTree(cur);
}


/**
 * Compiles the requirement expressions and generates a parser tree for each requirement
 *
 * @param {object} data an object containing the expressions to be compiled
 * @returns {object} the input object with an added parser tree
 */
export function compile(data) {
  const res = {};
  Object.keys(data).forEach(req => {
    try {
      const logic = data[req];
      res[req] = logic ? parser(tokenizer(data[req])) : null;
    }
    catch (err) {
      console.log('>> Error parsing rule', req);
      console.error(err);
      res[req] = null;
    }
  });
  return res;
}
