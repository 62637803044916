import React from 'react';

import allPrograms from '../../../data/programs.json';


function ProgramChoice({ chooseProgram }) {

  const onProgramChoice = (ev, prog, sub) => {
    ev.preventDefault();
    return chooseProgram?.(prog, sub);
  };

  return (
    <section>
      <h2>Hvilke linje går du?</h2>
      <ul>
        {Object.values(allPrograms).map((p, i) => (
          <li key={ i }>
            {p.skip
              ? <a href="/" onClick={ ev => onProgramChoice(ev, p.id, p.skip) }>{p.name}</a>
              : <>
                <span>{p.name}</span>
                <ul>
                  {Object.values(p.programs).map((q, j) => (
                    <li key={ j }>
                      &bull; <a href="/" onClick={ ev => onProgramChoice(ev, p.id, q.id) }>{q.name}</a>
                    </li>
                  ))}
                </ul>
              </>
            }
          </li>
        ))}
      </ul>
    </section>
  );
}

export default ProgramChoice;
