/**
 * Study requirements compiler
 *
 * @module compiler/study-requirements
 * @description
 * Requirement logic:
 * https://api.utdanning.no/karakterkalkulator/points?filename=kravkoder_logikk_SO.json
 *
 * Requirements (calculated and described):
 * https://api.utdanning.no/karakterkalkulator/points?filename=kravelementer.json
 *
 * @see module:compiler/study-requirements/frontend
 * @see module:compiler/study-requirements/backend
 */

import reqs from '../../data/requirement-logic.json';

import { compile } from './frontend';
import { explain, flatten, generator } from './backend';


// Compile the logic rules
const compiled = compile(reqs);
// console.log('Study requirements compiled:', compiled);


/**
 * Evaluates all the study requirements given a set of calculated and described inputs.
 * Returns an object with the results of the requirement evaluation.
 *
 * @param {object} calculated a key-value object with the calculated requirements
 * @param {object} described a key-value object with the described requirements
 * @returns {object} a key-value object with the results
 */
export function getStudyRequirements(calculated, described) {
  const data = {
    ...calculated,
    ...described,
    GENS: true,
  };
  const res = {};

  Object.keys(reqs).forEach(req => {
    try {
      res[req] = generator(compiled[req])(data, false);
    }
    catch (err) {
      console.log('Error evaluating requirement:', req);
      console.error(err);
      res[req] = null;
    }
  });

  return res;
}


/**
 * Evaluates one study requirement given a set of calculated and described inputs.
 * Returns a tree with a breakdown of the evaluation result and text descriptions.
 *
 * @param {string} req the requirement code
 * @param {object} calculated a key-value object with the calculated requirements
 * @param {object} described a key-value object with the described requirements
 * @returns {array} the evaluation result
 */
export function getRequirementBreakdown(req, calculated, described) {
  const data = {
    ...calculated,
    ...described,
    GENS: true,
  };
  try {
    const compiledReq = compiled[req];
    if (!compiledReq) {
      return null;
    }

    const res = generator(compiledReq)(data, true);
    const breakdown = explain(flatten(res));

    if (breakdown[1] !== 'og') {
      return [breakdown];
    }
    return breakdown[2];
  }
  catch (err) {
    console.log('Error breaking down requirement:', req);
    console.error(err);
    return null;
  }
}


/**
 * Returns a label that represents the Pass/Fail/Unkown status of a requirement.
 * Used for displaying requirements in the breakdown.
 *
 * @param {boolean} value the input value
 * @returns {string} the label
 */
export function passFail(value) {
  return value === null ? 'unknown' : value ? 'pass' : 'fail';
}
