import React from 'react';

import Radio from '../../../input/radio';

import './points.scss';


export default function Points({ name, options, ...props }) {
  return (
    <Radio
      className="input-radio--points"
      options={ options }
      name={ `points-${name}` }
      unselect
      { ...props } />
  );
}

Points.defaultProps = {
  options: [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
  ],
};
