import React from 'react';
import { useHistory } from 'react-router';

import { Button } from '@utdanningno/designsystem-react';

import { filterKey, toElem } from '../../../utils/helpers';
import { allSections as sections } from '../../../utils/studies';


export default function Breadbutton({ match, className }) {
  const history = useHistory();

  const { interest, category } = match.params;
  const intName = category && interest && filterKey(sections, interest);

  const onClick = () => {
    const next = category ? `/studievelger/${interest}` : `/studievelger`;
    history.push(next);
    toElem('selector-content');
  };

  return (
    <Button className={ className } direction="back" onClick={ onClick }>
      <span class="icon">
        <svg aria-hidden="true" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.55 2.9L5.45002 8L10.55 13.1" stroke="#333333" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </span>
      <span>Tilbake til {intName || 'Interesser'}</span>
    </Button>
  );
}
