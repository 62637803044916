import React from 'react';

import Section from '../../components/section';
import Scoreboard from '../../components/scoreboard';
// import LinkPanelButton from '../../components/link-panel/button';

import Calculator from './calculator';
import Frontpage from './frontpage';

import { isYobOver22 } from '../../utils/helpers';


function GradeCalculator({ showCalc, showFAQ, ...props }) {
  return (
    <div className="page-layout">
      <div className="page-layout__main" aria-label="Hovedinnhold">
        {showCalc
          ? <Calculator { ...props } />
          : <Frontpage { ...props } />
        }
      </div>
      <div className="page-layout__aside" aria-label="Skolepoeng">
        <Section>
          <Scoreboard
            points={ props.totals } showFAQ={ showFAQ }
            above22={ isYobOver22(props.yob) }
          />
        </Section>
      </div>
    </div>
  );
}

export default GradeCalculator;
