import React, { useState } from 'react';

import { searchSubjects } from '../../../utils/subjects';


let ts;

function SubjectSearch({ addSubject }) {

  const [q, setQ] = useState('');
  const [results, setResults] = useState(null);

  function search(str) {
    clearTimeout(ts);
    ts = setTimeout(() => {
      const res = searchSubjects(str);
      setResults(res);
    }, 500);
  }

  const onTextChange = ev => {
    const value = ev.target.value;
    setQ(value);

    if (value.length > 2) {
      return search(value);
    }
    setResults(null);
  };

  const onSubjectChoice = (ev, subj) => {
    ev.preventDefault();
    addSubject(subj.section, subj.id);

    setQ('');
    setResults(null);
  };

  return (
    <section>
      <h2>Legg til fag:</h2>
      <div><input type="text" value={ q } onChange={ onTextChange } /></div>
      <br />
      {results && !results[0] && <span>No results...</span>}
      {results && results[0] && (
        <ul>
          {results.map((s, idx) => (
            <li key={ idx }><a href="." onClick={ ev => onSubjectChoice(ev, s) }>[{s.id}] {s.name}</a></li>
          ))}
        </ul>
      )}
    </section>
  );
}


export default SubjectSearch;
