import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../../components/tooltip';

import { getHelpTexts } from '../../utils/texts';

import './styles.scss';


export default function ScoreboardMini({ above22, points = {}, className }) {

  const [schoolTip, setSchoolTip] = useState('');
  const [competeTip, setCompeteTip] = useState('');

  useEffect(() => {
    (async () => {
      const txt = await getHelpTexts();
      if (txt) {
        setSchoolTip(txt.skolepoeng || '');
        setCompeteTip(txt.konkurransepoeng || '');
      }
    })();
  }, []);


  const above22Class = above22 ? 'is-above-22' : '';

  return (
    <div
      className={ ['scoreboard-mini', above22Class, className].join(' ') }
    >
      <div className="scoreboard-mini__body">
        {!above22 && (
          <>
            <div className="scoreboard-mini__summary">
              <span>{points.school || 0}</span>
              <Tooltip
                title="Skolepoeng"
                description={ schoolTip }
              >Skolepoeng</Tooltip>
            </div>
          </>
        )}

        <div className="scoreboard-mini__summary">
          <span>{points.total || 0}</span>
          <Tooltip
            title="Konkurransepoeng"
            description={ competeTip }
          >Konkurransepoeng</Tooltip>
        </div>
      </div>
    </div>
  );
}


ScoreboardMini.propTypes = {
  above22: PropTypes.bool,
  points: PropTypes.object,
};

ScoreboardMini.defaultProps = {
  above22: false,
  points: {},
};
