import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../../tooltip';
import Infobox from '../../infobox';

import { getProgramOptions } from '../../../utils/programs';
import { getDismissed, getHelpTexts, setDismissed } from '../../../utils/texts';
import { Select } from '@utdanningno/designsystem-react-select';


const studyLines = getProgramOptions();


export default function EditProgramPath({ program, chooseProgram }) {

  // Tooltips and help texts
  // const [renewalTip, setRenewalTip] = useState('');
  const [privateTip, setPrivateTip] = useState('');

  const [natureProgInfo, setNatureProgInfo] = useState('');
  const [rule235Info, setRule235Info] = useState('');

  useEffect(() => {
    (async () => {
      const txt = await getHelpTexts();
      if (txt) {
        // setRenewalTip(txt.fagfornyelsen || '');
        setPrivateTip(txt.privatist || '');
        setNatureProgInfo(txt.naturbruk || '');
        setRule235Info(txt.regelen_23_5 || '');
      }
    })();
  }, []);


  // Program (study line)
  const selectedProgram = program ? (program[1] ? `${program[0]}.${program[1]}` : program[0]) : '';

  const onProgramSelect = value => {
    if (!value) {
      return;
    }
    const [p, s] = value.split('.');
    chooseProgram(p, s);
    setDismissed('program_choice', false);
  };

  // Program specific info text
  const onProgramInfoClose = () => {
    setDismissed('program_choice', selectedProgram);
  };
  const showProgramInfo = getDismissed('program_choice') !== selectedProgram;

  // A function for returning the program choosen on the previous page
  const findDefault = () => {
    const lines = studyLines.map(line => {
      if (!line.options) { return line; }
      return line.options;
    });
    return lines.flat().find(line => line.value === selectedProgram);
  };


  return (
    <>
      <h2>Din valgte linje</h2>
      <Select
        name="program-line"
        defaultValue={ findDefault }
        options={ studyLines }
        onChange={ value => onProgramSelect(value.value) }

      />

      {showProgramInfo && selectedProgram === 'NA.NANAB' &&
        <Infobox title="Om Vg3 naturbruk" description={ natureProgInfo } onClose={ onProgramInfoClose } />
      }
      {showProgramInfo && selectedProgram === '235.235PO' &&
        <Infobox title="Om 23/5-regelen" description={ rule235Info } onClose={ onProgramInfoClose } />
      }

      {/* <Tooltip
        className="mt-lg"
        title="Fagfornyelsen"
        description={ renewalTip }
      >
        <Check
          label="Jeg har tatt fag etter fagfornyelsen."
          name="after-subject-renewal"
          checked={ false }
          setChecked={ () => console.log('check') }
        />
      </Tooltip> */}

      <Tooltip
        className="mt-md"
        label="Har du tatt fag som privatist?"
        title="Privatist"
        description={ privateTip }
      />
    </>
  );
}


EditProgramPath.propTypes = {
  program: PropTypes.array,
  chooseProgram: PropTypes.func,
};

EditProgramPath.defaultProps = {
  program: null,
  chooseProgram: () => { },
};
