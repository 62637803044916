import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Input from '../../input/input';
import SearchResult from './result';

import { searchSubjects } from '../../../utils/subjects';

import './styles.scss';
import { toElem } from '../../../utils/helpers';

let searching;

export default function SubjectSearch({ addSubject }) {

  const [query, setQuery] = useState('');
  const [results, setResults] = useState(null);
  const displayedResults = useRef(null);

  const search = str => {
    clearTimeout(searching);
    searching = setTimeout(() => {
      const res = searchSubjects(str);
      setResults(res);
    }, 500);
  };

  const onSearchInput = value => {
    setQuery(value);
    setResults(null);

    if (value.length > 1) {
      search(value);
    }
  };

  const onSubjectChoice = subj => {
    setQuery('');
    setResults(null);

    addSubject(subj.section, subj.id);
    setTimeout(() => toElem(`subject-${subj.id}`), 250);
  };

  const clickListener = event => {
    if (!displayedResults.current.contains(event.target)) {
      onSearchInput('');
    }
  };

  useEffect(() => {
    document.addEventListener('click', clickListener);
    return () => document.removeEventListener('click', clickListener);
  });

  return (
    <>
      <h2>Legg til fag</h2>
      <Input
        label="Her kan du søke opp og legge til fag"
        type="search"
        name="subject-search"
        placeholder="Søk etter fagnavn eller kode"
        value={ query }
        setValue={ onSearchInput }
        alternative
        labelposition="above"
      />

      {results && !results[0] && <p>Ingen treff på <em>{query}</em></p>}

      {results && results[0] && (
        <div ref={ displayedResults } className="subject-search__results">
          {results.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())).map(subject => (
            <SearchResult key={ subject.id } subject={ subject } onSubjectChoice={ onSubjectChoice } />
          ))}
        </div>
      )}
    </>
  );
}


SubjectSearch.propTypes = {
  addSubject: PropTypes.func,
};

SearchResult.defaultProps = {
  addSubject: () => { },
};
