/**
 * Calculator engine
 * @module utils/calculator
 * @description Set of functions that perform the basic calculator operations
 */

import additional from '../data/additional-points.json';
import scienceLangPoints from '../data/science-lang-points.json';

import { getSubjects } from './subjects';


const allSubjects = getSubjects();


/**
 * Returns a list of grades from the subject map.
 * Removes all null values.
 *
 * @param {object} subjectMap the subject map for the program line selected
 * @returns {array} An array with all the grades submitted
 */
export function getGradePoints(subjectMap) {
  const allPoints = [];
  Object.keys(subjectMap).forEach(sec => {
    switch (sec) {
      case 'language':
        if (subjectMap.language?.value) {
          allPoints.push(...subjectMap.language.value);
        }
        return;
      case 'special':
        return subjectMap.special && Object.keys(subjectMap.special).forEach(sub => {
          if (subjectMap.special[sub].selected && subjectMap.special[sub].value) {
            const subject = allSubjects[subjectMap.special[sub].selected];
            if (subject && (subject.type === 1 || subject.type === 2)) {
              allPoints.push(...subjectMap.special[sub].value);
            }
          }
        });
      default:
        break;
    }
    Object.keys(subjectMap[sec]).forEach(sub => {
      const subject = allSubjects[sub];
      if (subjectMap[sec][sub] && subject && (subject.type === 1 || subject.type === 2)) {
        allPoints.push(...subjectMap[sec][sub]);
      }
    });
  });
  return allPoints.filter(p => !!p);
}


/**
 * Calculates the science and language credits for a given subject map.
 * Limited to a max of 4 points.
 * Source: https://www.samordnaopptak.no/info/opptak/opptak-uhg/poengberegning/legge-til-poeng/realfagspoeng/
 *
 * The values for each subject specific subject are retrieved from the API
 * Source: https://api.utdanning.no/karakterkalkulator/points?filename=points_per_subject_per_category.json
 *
 * @param {object} subjectMap the subject map for the program line selected
 * @returns {number} the extra credits amount
 */
export function getSciencePoints(subjectMap) {
  const scores = {};
  Object.keys(subjectMap).forEach(sec => {
    if (sec === 'language' || sec === 'special') {
      return;
    }

    Object.keys(subjectMap[sec]).forEach(subj => {
      const subject = subjectMap[sec][subj];
      if (subject && ((subject[0] && subject[0] > 1) || (subject[1] && subject[1] > 1))) {
        scores[subj] = true;
      }
    });
  });

  let total = 0;
  Object.keys(scienceLangPoints).forEach(section => {
    let sectionPoints = 0;
    Object.keys(scienceLangPoints[section]).forEach(subj => {
      let subjPoints = 0;
      Object.keys(scienceLangPoints[section][subj]).forEach(id => {
        if (scores[id]) {
          subjPoints += scienceLangPoints[section][subj][id];
        }
      });
      if (subjPoints > 1.5) {
        subjPoints = 1.5;
      }
      sectionPoints += subjPoints;
    });
    if (sectionPoints > 4) {
      sectionPoints = 4;
    }
    total += sectionPoints;
  });
  if (total > 4) {
    total = 4;
  }
  return total;
}


/**
 * Calculates the number of age points for the year of birth
 * Limited to a max of 8 points.
 * Source: https://www.samordnaopptak.no/info/opptak/opptak-uhg/poengberegning/legge-til-poeng/alderspoeng/
 * Also: https://www.samordnaopptak.no/info/opptak/opptak-uhg/generell-studiekompetanse/23-5-regelen/
 *
 * @param {string} yearOfBirth the year of birth
 * @param {boolean} rule235 boolean indicating if the 23/5 should be applied
 * @returns {number} the additional age points
 */
export function getAgePoints(yearOfBirth, rule235 = false) {
  if (yearOfBirth) {
    const offset = rule235 ? 23 : 19;
    const year = new Date().getFullYear();
    const yob = parseInt(yearOfBirth);
    if (yob > 1920 || yob < year) {
      const diff = 2 * (year - yob - offset);
      return diff < 0 ? 0 : (diff > 8 ? 8 : diff);
    }
  }
  return 0;
}


/**
 * Calculates the number of additional points
 * Limited to a max of 2 points.
 * Source: https://www.samordnaopptak.no/info/opptak/opptak-uhg/poengberegning/legge-til-poeng/tilleggspoeng/
 *
 * @param {object} addPoints key-value object with the options selected
 * @returns {number} the additional points
 */
export function getAdditionalPoints(addPoints) {
  let points = 0;
  Object.keys(additional).forEach(p => {
    if (addPoints[p]) {
      points += additional[p].points || 0;
    }
  });
  if (points > 2) {
    points = 2;
  }
  return points;
}


/**
 * Calculates the total number of points
 *
 * @param {object} subjectMap a map with the list of subjects per section
 * @param {object} addPoints a key-value object with the additional options selected
 * @param {string} yob the year of birth
 * @param {boolean} rule235 indicates if the 23/5 rule should be applied
 * @returns {object} a key-value object with number of points per type
 */
export function calculatePoints(subjectMap, addPoints, yob, rule235 = false) {
  const points = {
    grade: 0, credits: 0, school: 0, age: 0, extra: 0, total: 0,
  };

  // Grade points (study plan)
  const subjPoints = getGradePoints(subjectMap);
  const avgPoints = subjPoints.reduce((a, b) => a + b, 0) / (subjPoints.length || 1);
  points.grade = Math.round(avgPoints * 100) / 10;

  // Language and science credits
  points.credits = getSciencePoints(subjectMap);

  // Age points
  points.age = getAgePoints(yob, rule235);

  // Additional points
  points.extra = getAdditionalPoints(addPoints);

  // Totals
  points.school = points.grade + points.credits;
  points.total = points.school + points.age + points.extra;
  return points;
}
