import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { filterKey, toElem } from '../../../utils/helpers';
import { allSections as sections } from '../../../utils/studies';

import './styles.scss';


export default function Breadcrumb({ name, match, className }) {

  const { interest, category } = match.params;
  const intName = interest && filterKey(sections, interest);
  const catName = intName && filterKey(sections[intName], category);

  const items = [['', 'Interesser', true]];
  if (intName) {
    items.push(
      [`/${interest}`, intName],
    );
    if (catName) {
      items.push(
        [`/${interest}/${category}`, catName],
      );
      items[1].push(true);
    }
  }

  const onClick = () => {
    toElem('selector-content');
  };

  return (
    <nav
      className={ ['breadcrumb', className].join(' ').trim() }
    >
      <h2 className="visuallyhidden">{name}</h2>
      <ul>
        {items.map((item, i) => (
          <li key={ i }>
            {item[2]
              ? <Link to={ `/studievelger${item[0]}` } onClick={ onClick }>{item[1]}</Link>
              : item[1]
            }
          </li>
        ))}
      </ul>
    </nav>
  );
};


Breadcrumb.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
};

Breadcrumb.defaultProps = {
  name: 'Brødsmulesti for studievelger',
};
