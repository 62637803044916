import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as SearchIcon } from '../../../assets/search.svg';
import { ReactComponent as IconPlus } from '../../../assets/plus-circle.svg';
import { ReactComponent as IconMinus } from '../../../assets/minus-circle.svg';
import { ReactComponent as IconThumb } from '../../../assets/thumb.svg';

import './styles.scss';

export default function Input({
  label,
  type,
  name,
  value,
  setValue,
  alternative,
  labelposition,
  className,
  ...props
}) {
  const classType = alternative ? `input--${type}-alt` : `input--${type}`;

  let classLabelPosition = '';
  let separateLabel = false;

  switch (labelposition) {
    case 'above':
      classLabelPosition = 'input--label-above';
      separateLabel = true;
      break;
    case 'hidden':
      classLabelPosition = 'input--label-hidden';
      separateLabel = true;
      break;
    default:
      break;
  }

  const [isInvalidClass, setInvalidClass] = useState('');

  const handleValidation = e =>
    setInvalidClass(!e.target.validity.valid ? 'is-invalid' : '');

  const searchIcon = () =>
    alternative ? (
      <span className="icon-container">
        <SearchIcon aria-hidden />
      </span>
    ) : (
      <SearchIcon />
    );

  function onChange(e) {
    return setValue?.(e.target.value);
  }

  const [inputMinMax, setInputMinMax] = useState([0, 0]);

  useEffect(() => {
    if (type === 'range') {
      setInputMinMax([parseInt(props.min) || 0, parseInt(props.max || 0)]);
    }
  }, [type, props.max, props.min]);

  function handleAdjust(msg) {
    if (msg === 'increase' && value < inputMinMax[1]) {
      return setValue?.(value + 1);
    }
    else if (msg === 'decrease' && value > inputMinMax[0]) {
      return setValue?.(value - 1);
    }
  }

  return (
    <div
      className={ [
        'input',
        classType,
        classLabelPosition,
        isInvalidClass,
        className,
      ]
      .join(' ')
      .trim() }
    >
      <label htmlFor={ props.id || name }>
        {label}
        {labelposition === 'inline' && (
          <input
            type={ type }
            id={ props.id || name }
            name={ name }
            value={ value }
            onBlur={ handleValidation }
            onChange={ onChange }
            { ...props }
          />
        )}
      </label>
      {separateLabel && (
        <input
          type={ type }
          id={ props.id || name }
          name={ name }
          value={ value }
          onBlur={ handleValidation }
          onChange={ onChange }
          { ...props }
        />
      )}
      {type === 'search' && searchIcon()}
      {type === 'range' && (
        <>
          <span
            className="input__thumb"
            style={ {
              left: `calc(${(
                value / (inputMinMax[1] - inputMinMax[0] || value)
              ).toFixed(3)} * (100% - 32px))`,
            } }
          >
            <span className="input__value">{value}</span>
            <IconThumb aria-hidden />
          </span>
          <div className="input__buttons">
            <button onClick={ () => handleAdjust('decrease') }>
              <IconMinus className="icon-minus" aria-label="Minus" />
            </button>
            <button onClick={ () => handleAdjust('increase') }>
              <IconPlus className="icon-plus" aria-label="Pluss" />
            </button>
          </div>
        </>
      )}
    </div>
  );
}

Input.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    'text',
    'number',
    'email',
    'password',
    'tel',
    'search',
    'range',
  ]),
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setValue: PropTypes.func,
  alternative: PropTypes.bool,
  labelposition: PropTypes.oneOf(['inline', 'above', 'hidden']),
  className: PropTypes.string,
};

Input.defaultProps = {
  type: 'text',
  labelposition: 'inline',
  // alternative: true,
};
