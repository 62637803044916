import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as IconCheck } from '../../../../assets/check-circle-fill.svg';
import { ReactComponent as IconCross } from '../../../../assets/cross-circle-fill.svg';
import { ReactComponent as IconQuestion } from '../../../../assets/question-circle-fill.svg';

import './styles.scss';


export default function Requirement({ text, label, type, children, className, showIcon }) {
  let Icon;
  let classType;

  if (typeof showIcon === 'undefined') {
    showIcon = true;
  }

  switch (type) {
    case 'pass':
      Icon = IconCheck;
      classType = 'requirement--pass';
      break;
    case 'fail':
      Icon = IconCross;
      classType = 'requirement--fail';
      break;
    case 'unknown':
      Icon = IconQuestion;
      classType = 'requirement--unknown';
      break;
    default:
      classType = 'requirement--text';
      break;
  }

  const classHasLabel = label ? 'requirement--label' : '';

  return (
    <div
      className={ ['requirement', classType, classHasLabel, className].join(' ') }
    >
      {label && <div className="requirement__label">{label}</div>}
      <div className="requirement__content">
        {showIcon && Icon && <Icon className="requirement__icon" />}
        <span>{children ? children : text}</span>
      </div>
    </div>
  );
}


Requirement.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  label: PropTypes.string,
  type: PropTypes.string,
};
