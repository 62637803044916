import React from 'react';

import Section from '../../components/section';
import IntroForm from '../../components/calculator/intro-form';
import { Button } from '@utdanningno/designsystem-react';
import { ReactComponent as Logo } from '../../assets/logo.svg';

import './styles.scss';

function Frontpage({ totals, showCalculator, ...props }) {
  return (
    <>
      <Section width="wide">
        <div className="frontpage-heading">
          <Logo />
          <h1>Karakter&shy;kalkulatoren</h1>
        </div>
        <div className="p-container">
          <p className="h3">Vil du studere på universitet eller høgskole?</p>
          <p>Bruk Karakterkalkulatoren til å regne ut poengene dine.</p>
          <p>
            Med studievelger kan du se hvilke studier du kan komme inn på med
            dine poeng. Den viser deg også hvilke fag du må ha på videregående,
            for å komme inn på ulike studier.
          </p>
          <p className="h3">Vet du allerede snittet ditt?</p>
          <Button href="/karakterkalkulator/studievelger" chevron>
            Gå rett til Studievelger
          </Button>
        </div>
      </Section>
      <IntroForm { ...props } submitForm={ showCalculator } />
    </>
  );
}

export default Frontpage;
