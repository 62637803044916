import React from 'react';
import PropTypes from 'prop-types';

import Points from './score/points';
import { FailPass, FailPassGood, Participated } from './score/option';


const SCORE_COMPS = {
  1: Points,
  2: Points,
  3: Participated,
  4: FailPass,
  5: FailPassGood,
};


export default function SubjectGrade({ name, type, value, setValue, selected }) {
  const C = SCORE_COMPS[type];

  let disabled = false;
  if (typeof selected !== 'undefined') {
    disabled = (selected === null);
  }

  if (!C) {
    return null;
  }
  return (
    <C
      name={ name }
      selected={ value }
      disabled={ disabled }
      setRadio={ newValue => setValue(newValue) }
      setRadioReset={ () => setValue(null) }
    />
  );
}

SubjectGrade.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.number.isRequired,
  value: PropTypes.number,
  setValue: PropTypes.func.isRequired,
};
