import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import ProgramList from './program-list';
import AdditionalPoints from './add-points';
import SubjectMap from './subject-map';
import SubjectSearch from './subject-search';
import Scoreboard from './scoreboard';
import Requirements from '../requirements';

import { calculateRequirements, getDescribedRequirements } from '../../../utils/subject-logic';
import { getStudyRequirements } from '../../../utils/study-logic';
import { countMatchingStudies } from '../../../utils/studies';


const TwoCols = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const Spacer = styled.div`
  height: ${props => props.height || 0}px;
`;


function GradeCalculator({
  // State
  program,
  subjectMap,
  addPoints,
  yob,
  totals,

  // Actions
  chooseProgram,
  addSubject,
  removeSubject,
  chooseSubject,
  setScore,
  setAddPoints,
  setYob,
}) {
  const [requirements, setRequirements] = useState({});
  const [numMatches, setNumMatches] = useState(0);


  // Score calculation

  useEffect(() => {
    // Calculate requirements and matches
    const calc = calculateRequirements(subjectMap, totals);
    const desc = getDescribedRequirements();
    const study = getStudyRequirements(calc, desc);

    setRequirements({
      calculated: calc, described: desc, study: study,
    });

    const newNumMatches = countMatchingStudies(study, totals);
    setNumMatches(newNumMatches.$);
  },
  [subjectMap, totals]);


  return (
    <section>
      <h1>Grade Calculator</h1>
      <TwoCols>
        <ProgramList chooseProgram={ chooseProgram } />
        <AdditionalPoints
          values={ addPoints }
          setValues={ setAddPoints }
          yob={ yob }
          setYob={ setYob }
        />
      </TwoCols>

      {program &&
        <div>
          <SubjectMap
            program={ program }
            subjectMap={ subjectMap }
            removeSubject={ removeSubject }
            chooseSubject={ chooseSubject }
            setScore={ setScore }
          />
          <SubjectSearch addSubject={ addSubject } />
        </div>
      }

      <Spacer height="150" />
      <Scoreboard points={ totals } numMatches={ numMatches } />

      <Requirements
        reqs={ requirements.calculated }
        position="A" title="Calculated Requirements"
      />
      <Requirements
        reqs={ requirements.study }
        position="D" title="Study Requirements (Kravkode)"
      />
    </section>
  );
}

export default GradeCalculator;
