import React from 'react';
import styled from 'styled-components';


const Option = styled.div`
  display: inline-block;
  margin: 2px;
  padding: 2px 6px;
  background-color: #EEE;

  &:hover, &:focus {
    background-color: #DDD;
  }

  ${props => props.selected && `
    color: #FFF;
    background-color: #000;

    &:hover, &:focus {
      background-color: #333;
    }
  `}
`;


function OptionGroup({ options, section, subject, type, value, setScore }) {

  const onOptionSelect = (ev, newValue) => {
    ev.preventDefault();
    console.log('setValue:', section, subject, type, newValue);
    return setScore?.(section, subject, type, newValue === value ? null : newValue);
  };

  return (
    <div>
      {options.map((str, idx) => (
        <a key={ idx } href="." onClick={ ev => onOptionSelect(ev, idx + 1) }>
          <Option selected={ value === idx + 1 }>{str}</Option>
        </a>
      ))}
    </div>
  );
}


export function GoodPassFail(props) {
  const options = ['Ikke bestått', 'Bestått', 'Bestått meget godt'];
  return <OptionGroup options={ options } { ...props } />;
}


export function PassFail(props) {
  const options = ['Ikke bestått', 'Bestått'];
  return <OptionGroup options={ options } { ...props } />;
}


export function Participated(props) {
  const options = ['Ikke Deltatt', 'Deltatt'];
  return <OptionGroup options={ options } { ...props } />;
}
