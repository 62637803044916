import React from 'react';
import styled from 'styled-components';

import { getOk } from '../../../utils/helpers';
import { getRequirementBreakdown } from '../../../utils/study-logic';


const PaddedList = styled.ul`
  li {
    display: block;
    margin-bottom: 10px;
  }
  ul {
    margin-top: 10px;
    padding-left: 20px;
  }
`;


function BreakdownItem({ node }) {
  const labels = {
    eller: <strong>Ett av disse kravene:</strong>,
    og: <strong>Alle disse kravene:</strong>,
  };
  return (
    <li>
      <span>{getOk(node[0])} {labels[node[1]] || node[1]}</span>
      {node[2] && <BreakdownList items={ node[2] } />}
    </li>
  );
}


function BreakdownList({ items }) {
  return (
    <PaddedList>
      {items.map((child, i) => <BreakdownItem key={ i } node={ child } />)}
    </PaddedList>
  );
}


function RequirementBreakdown({ code, requirements }) {
  const { calculated, described } = requirements;
  const breakdown = getRequirementBreakdown(code, calculated, described);

  if (code === 'GENS') {
    return <h4>Du må dokumentere generell studiekompetanse uten tilleggskrav.</h4>;
  }

  let addGens = false;
  if (breakdown && breakdown[0] && breakdown[0][1] === 'GENS') {
    addGens = true;
    breakdown.shift();
  }

  return breakdown?.length
    ? <>
      {addGens ? <h4>I tillegg til generell studiekompetanse må du ha:</h4> : <h4>Du må ha:</h4>}
      <BreakdownList items={ breakdown } />
    </>
    : null;
}

export default RequirementBreakdown;
