import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { filterKey, toTop } from '../../../utils/helpers';


const CrumbList = styled.ul`
  margin: 20px 0;

  li {
    display: inline-block;
    &:not(:first-child) {
      &::before {
        content: ">";
      }
      a {
        margin-left: 5px;
      }
    }
    &:not(:last-child) {
      margin-right: 5px;
    }
  }

  a {
    display: inline-block;
  }
`;


function Breadcrumbs({ match, sections, setSearchStr }) {
  const { interest, category } = match.params;
  const intName = interest && filterKey(sections, interest);
  const catName = intName && filterKey(sections[intName], category);

  const items = [['', 'Studiekateorier']];
  if (intName) {
    items.push(
      [`/${interest}`, intName],
    );
    if (catName) {
      items.push(
        [`/${interest}/${category}`, catName],
      );
    }
  }

  const onClick = () => {
    toTop();
    return setSearchStr?.('');
  };

  return (
    <CrumbList>
      {items.map((item, i) => (
        <li key={ i }>
          <Link to={ `/proto/study-selector${item[0]}` } onClick={ onClick }>{item[1]}</Link>
        </li>
      ))}
    </CrumbList>
  );
}

export default Breadcrumbs;
