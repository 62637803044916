/**
 * Study requirements compiler backend
 * @module compiler/study-requirements/backend
 */

import reqsraw from '../../data/requirements.json';
import allSubjects from '../../data/subjects.json';
import { logicOp, compOp } from './frontend';


const reqs = {
  ...reqsraw.calculated,
  ...reqsraw.described,
  // GENS: 'Generell studiekompetanse',
};

// Comparison descriptions
const compareStrs = {
  '>=': 'større enn eller lik',
  '<=': 'lavere enn eller lik',
  '>': 'større enn',
  '<': 'lavere enn',
  '==': 'lik',
  '!=': 'annerledes enn',
};


// Compiler backend


/**
 * Evaluates a comparison operator given its parameters.
 *
 * @param {string} op the comparisor operator
 * @param {number} a parameter A
 * @param {number} b parameter B
 * @returns {boolean} the result of the comparison
 */
function compare(op, a, b) {
  switch (op) {
    case '==': return a === b;
    case '!=': return a !== b;
    case '>': return a > b;
    case '>=': return a >= b;
    case '<': return a < b;
    case '<=': return a <= b;
    default: return false;
  }
}

/**
 * Evaluates a logical operator given its parameters.
 *  - og: AND operator
 *  - eller: OR operator
 *
 * @param {string} op the logical operator operator
 * @param {number} a parameter A
 * @param {number} b parameter B
 * @returns {boolean} the result of the comparison
 */
function logic(op, a, b) {
  switch (op) {
    case 'og': return a === true ? b : b === true ? a : a && b;
    case 'eller': return a === false ? b : b === false ? a : a || b;
    default: return false;
  }
}


/**
 * Generates an executable program for a give tree.
 * The return function will be specific for this language.
 *
 * Optionally, the program can return the binary evaulation tree that
 * shows how nodes were evaluated.
 *
 * @param {object} tree the program parser tree
 * @returns {function} the executable function
 *
 */
export function generator(tree) {
  /**
   * Evalutes the result for a node given an input
   *
   * @inner
   * @param {object} node the node to be evaluatred
   * @param {object} input the input data for the program
   * @returns {array} the result of the evaluation and its children
   */
  function evaluate(node, input) {
    const { type, value, children } = node;

    if (type === 'OP') {
      const a = evaluate(children[0], input);
      const b = evaluate(children[1], input);

      return [logic(value, a[0], b[0]), value, [a, b]];
    }

    if (type === 'COMP') {
      const a = evaluate(children[0], input);
      const b = evaluate(children[1], input);

      return [compare(value, a[0], b[0]), value, [a, b]];
    }

    if (type === 'GRP') {
      if (children && children[0]) {
        return evaluate(children[0], input);
      }
      return [false];
    }

    if (type === 'VAR') {
      if (typeof value === 'number') {
        return [value, 'number'];
      }
      switch (value) {
        case 'NORSK':
          input[value] = avgNorsk();
          break;
        case 'NORH':
          input[value] = avgNorsk(true);
          break;
        case 'FFMAT':
          input[value] = avgMath();
          break;
        default:
          break;
      }
      return [input[value], value];
    }

    return [false, 'bool'];
  }

  return function (input, incTree) {
    if (!tree) {
      return false;
    }
    const res = evaluate(tree, input);
    return incTree ? res : res && res[0];
  };
}

function getSubjects() {
  const savedJson = sessionStorage.getItem('GRADE_CALC');
  const saved = JSON.parse(savedJson);
  if (!saved) {
    return null;
  }
  return saved.subjectMap;
}

function avgNorsk(hovedmaal = false) {
  const subjectMap = getSubjects();
  if (!subjectMap) {
    return 0;
  }
  const common = subjectMap.common;
  let counter = 0, sum = 0;
  for (const code in common) {
    if (/^NOR/.test(code)) {
      const value = common[code];
      if (value) {
        const subject = allSubjects[code];
        for (const character of value) {
          if (character) {
            if (hovedmaal) {
              if (subject.type === 1) {
                sum += character;
                counter++;
              }
            }
            else if (subject.type < 3) {
              sum += character;
              counter++;
            }
          }
        }
      }
    }
  }

  return sum / counter;
}

function avgMath() {
  const subjectMap = getSubjects();
  if (!subjectMap) {
    return 0;
  }

  let counter = 0, sum = 0;

  const special = subjectMap.special;
  for (const code in special) {
    if (/^matematikk\d+$/.test(code)) {
      const item = special[code];
      if (item && item.value) {
        for (const character of item.value) {
          if (character) {
            sum += character;
            counter++;
          }
        }
      }
    }
  }

  const common = subjectMap.common;
  for (const code in common) {
    if (/^MAT\d+$/.test(code)) {
      const item = common[code];
      if (item && item) {
        for (const character of item) {
          if (character) {
            sum += character;
            counter++;
          }
        }
      }
    }
  }

  return sum / counter;
}


/**
 * Flattens the evaluation tree (that resulted from this program's execution).
 * Combines the operators that are on the same level (OR / AND) turning the
 * binary tree into a tree that can have more children.
 *
 * @param {array} node the input node
 * @returns {array} the resulting flattened node
 */
export function flatten(node) {
  const [value, name, children] = node;

  if (!children || !children.length) {
    return [value, name];
  }

  const [a, b] = children.map(child => flatten(child));
  const combined = a[1] === name ? [...a[2], b] : [a, b];
  return [value, name, combined];
}


/**
 * Converts a flat result tree to a text description.
 * However, it only works within limited language parameters.
 * These text descriptions are then used in the study details to explain why
 * requirements were met or failed.
 *
 * @param {array} node the node to be explained
 * @param {number} depth the depth of the tree so far
 * @returns {array} the explained node
 */
export function explain(node, depth = 0) {
  const [value, name, children] = node;

  // Values
  if (!children) {
    const req = reqs[name];

    // boolean
    if (typeof value === 'boolean' || value === null) {
      return [value, req || name];
    }
    // number (or null)
    return [value, req || name];
  }

  // Convert children
  const combined = children.map(child => explain(child, depth + 1));

  // Comparison
  if (compOp.includes(name)) {
    return [value, `${combined[0][1]} ${compareStrs[name] || name} ${combined[1][0]}`];
  }

  // Operators
  if (logicOp.includes(name)) {
    if (name === 'og' && depth > 0) {
      const start = combined.slice();
      const last = start.pop();
      return [value, `${start.map(item => item[1]).join(', ')} og ${last[1]}`];
    }
    return [value, name, combined];
  }

  throw new Error(`Unknown operator: ${name}`);
}
