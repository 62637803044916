/* eslint no-extend-native: off */
/**
 * Helpers
 * @module utils/helpers
 * @description Collection of helper functions
 */


// Quick polyfill for remove operations
Array.prototype.pull = function (item) {
  const idx = this.indexOf(item);
  return idx >= 0 && this.splice(idx, 1);
};


/**
 * Returns an emoji that represents the requirement status.
 * Used in the prototype only.
 *
 * @param {boolean} value the requirement status
 * @returns {string} the emoji
 */
export function getOk(value) {
  return value === null ? '❓' : (value ? '✅' : '❌');
}


/**
 * Slugifies a string.
 * Replaces non-word characters with a separator.
 *
 * @param {string} str the input string
 * @returns {string}
 */
export function slugify(str) {
  return str?.toLowerCase()
  .replace(/æ/g, 'ae').replace(/[øö]/g, 'o').replace(/[åä]/g, 'a')
  .replace(/[^\w]+/g, '-').replace(/-+/g, '-').replace(/(^-+)|(-+$)/g, '');
}


/**
 * Returns an object key that matches slugiffied parameter.
 * Used for matching a url token to a section (interest or category) in the study list.
 *
 * @param {object} obj a key-value input object
 * @param {string} str the slugiffied string to search for
 * @returns {string} the matched key
 */
export function filterKey(obj, str) {
  const keys = obj && Object.keys(obj);
  return keys?.filter(k => slugify(k) === str)[0];
}


/**
 * Helper function for sorting arrays alphabetically
 *
 * @param {number} a param A
 * @param {number} b param B
 * @returns {number} returns 1 if B is smaller that A, -1 otherwise
 */
export function abcSort(a, b) {
  return b < a ? 1 : -1;
}


/**
 * Helper function to scroll the user to the top of the window
 */
export function toTop() {
  setTimeout(() => window.scrollTo(0, 0), 50);
}


/**
 * Helper function to scroll the user to a certain element
 *
 * @param {string} id the element id
 */
export function toElem(id) {
  setTimeout(() => {
    const elem = document.getElementById(id);
    if (elem) {
      elem.scrollIntoView();
    }
  }, 50);
}


const currYear = new Date().getFullYear();

/**
 * Checks if the year of birth was over 22 years ago.
 *
 * @param {string} yearOfBirth the year of birth
 * @returns {boolean}
 */
export function isYobOver22(yearOfBirth) {
  const yobInt = parseInt(yearOfBirth) || 0;
  return yobInt > 1920 && yobInt <= (currYear - 22);
}


/**
 * Checks if the year of birth was in or before 1989.
 *
 * @param {string} yearOfBirth the year of birth
 * @returns {boolean}
 */
export function isYobBefore1989(yearOfBirth) {
  const yobInt = parseInt(yearOfBirth) || 0;
  return yobInt > 1920 && yobInt <= 1989;
}
