import React from 'react';
import styled from 'styled-components';


const Number = styled.span`
  display: inline-block;
  width: 22px;
  margin: 2px;

  border-radius: 50%;
  background-color: #EEE;

  text-align: center;
  line-height: 22px;

  &:hover, &:focus {
    background-color: #DDD;
  }

  ${props => props.selected && `
    color: #FFF;
    background-color: #000;

    &:hover, &:focus {
      background-color: #333;
    }
  `}
`;

const points = [1, 2, 3, 4, 5, 6];


function PointsList({ section, subject, type, value, setScore }) {

  const onScoreChoice = (ev, newValue) => {
    ev.preventDefault();
    console.log('setValue:', section, subject, type, newValue);
    return setScore?.(section, subject, type, newValue === value ? null : newValue);
  };

  return (
    <center>
      {points.map((p, idx) => (
        <a key={ idx } href="." onClick={ ev => onScoreChoice(ev, p) }>
          <Number selected={ p === value }>{p}</Number>
        </a>
      ))}
    </center>
  );
}

export default PointsList;
