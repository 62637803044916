import React from "react";
import PropTypes from 'prop-types';

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  children: PropTypes.any,
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  onChange: () => null,
};

export function Checkbox({label, id, name, value, className, onChange, children, ...rest}) {
  const classes = [
    `checkbox__input`,
    className
  ].join(` `);

  /**
   * onChange decorator
   * @param event
   */
  const handleChange = event => {
    if (onChange) onChange(event);
  };

  return (
    <label className={'checkbox'}>
      <input
        type="checkbox"
        id={id}
        name={name}
        value={value}
        className={classes}
        onChange={handleChange}
        {...rest}
      />
      <span className={'checkbox-text'}>{children || label}</span>
    </label>
  );
}

export default Checkbox;
