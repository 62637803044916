import React from 'react';


const labels = {
  // 1 & 2: Numeric values
  3: ['-', 'Ikke deltatt', 'Deltatt'],
  4: ['-', 'Ikke bestått', 'Bestått'],
  5: ['-', 'Ikke bestått', 'Bestått', 'Bestått meget godt'],
};


export default function CollapsedSubject({
  subject,
  value,
  setActiveSubject,
}) {
  const { id, name, type } = subject;

  const posLabel = value?.[0] ? labels[type]?.[value[0]] || value[0] : '-';
  const examLabel = value?.[1] ? labels[type]?.[value[1]] || value[1] : '-';

  return (
    <button
      type="button"
      className="subject__btn-maximize"
      onClick={ () => setActiveSubject(id) }
    >
      <div>
        <h4 className=" subject__name">{name}</h4>
        <span className="subject__grade">{posLabel}</span>
      </div>
      {value?.[1] && (
        <div className="subject__examn">
          <span>Eksamen</span>
          <span>{examLabel}</span>
        </div>
      )}
    </button>
  );
}
