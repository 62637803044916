import React from 'react';
import PropTypes from 'prop-types';

import Requirement from '../requirement';
import { getRequirementBreakdown, passFail } from '../../../../utils/study-logic';

import './styles.scss';


export default function RequirementGroup({ code, requirements }) {

  const { calculated, described } = requirements;
  const breakdown = getRequirementBreakdown(code, calculated, described);

  let addGens = false;
  if (breakdown && breakdown[0] && breakdown[0][1] === 'GENS') {
    addGens = true;
    breakdown.shift();
  }

  return (
    <>
      <h4 className="study__title-2 mb-2xs">Disse fagene og karakterene må du ha:</h4>
      <p>Kravkode: {code}</p>
      {breakdown && (!breakdown.length
        ? <p className="mt-sm">Du må dokumentere generell studiekompetanse uten tilleggskrav.</p>
        : <>
          <div className="requirement-group">
            <p className="mt-sm">{addGens ? 'I tillegg til generell studiekompetanse må du ha:' : 'Du må ha:'}</p>
            <RequirementBreakdown list={ breakdown } />
          </div>
        </>
      )}
    </>
  );
}


function RequirementBreakdown({ list }) {
  return (
    <ul className="list-reset">
      {list.map((item, i) => (
        <li key={ i }>
          {item[1] === 'eller'
            ? <>
              <Requirement text="Ett av disse kravene:" type={ passFail(item[0]) } showIcon={ false } />
              <RequirementBreakdown list={ item[2] } />
            </>
            : <Requirement text={ item[1] } type={ passFail(item[0]) } />
          }
        </li>
      ))}
    </ul>
  );
}


RequirementGroup.propTypes = {
  label: PropTypes.string,
};
