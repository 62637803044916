import styled from 'styled-components';
import { Link } from 'react-router-dom';


const ctaStyle = `
  padding: 10px 20px;
  color: black;

  color: black;
  background-color: #FF9C43;
  border: none;
  border-radius: 20px;

  &:hover {
    color: black;
    background-color: #E6842A;
    text-decoration: none;
  }
`;

export const Button = styled.button`${ctaStyle}`;

export const CtaLink = styled(Link)`${ctaStyle}`;
