/**
 * Text helpers
 * @module utils/texts
 * @description Collection of helper functions editable texts (YAML files)
 */

import { load as safeLoad } from 'js-yaml';

import HELP_TEXTS from '../data/help-texts.yml';
import FAQ from '../data/faq.yml';


const ymlCache = {};


/**
 * Loads data from a YAML file.
 * This data is cached for better performance.
 *
 * @param {string} path the path to the file
 * @returns {object} the object representing the yaml file
 */
async function getYaml(path) {
  if (ymlCache[path]) {
    return Promise.resolve(ymlCache[path]);
  }
  try {
    const res = await fetch(path);
    const yaml = await res.text();

    const data = safeLoad(yaml);
    if (typeof data !== 'object') {
      console.error('Invalid yaml format:', path);
      return null;
    }

    ymlCache[path] = data;
    return data;
  }
  catch (err) {
    console.error(err);
    return null;
  }
}


// Help texts

let helpTexts;
let pending;

/**
 * Retrives the help texts from the yml file
 *
 * @returns {Promise<object>} promise with the help texts
 */
export function getHelpTexts() {
  if (helpTexts) {
    return Promise.resolve(helpTexts);
  }
  if (!pending) {
    pending = new Promise(async resolve => {
      const data = await getYaml(HELP_TEXTS);
      helpTexts = data || {};
      resolve(helpTexts);
    });
  }
  return pending;
}


// FAQ

/**
 * Retrives the FAQ questions from the yml file
 *
 * @returns {Promise<object>} promise with the faq text
 */
export async function getFAQ() {
  const data = await getYaml(FAQ);
  return data || [];
}


// Dismissed info texts

const INFOTEXTS = window.sessionStorage.getItem('GRADE_CALC_INFOTEXTS');
const infoTexts = (INFOTEXTS && JSON.parse(INFOTEXTS)) || {};


export function getDismissed(name) {
  return infoTexts[name] || false;
}

export function setDismissed(name, value) {
  if (!value) {
    delete infoTexts[name];
  }
  else {
    infoTexts[name] = value;
  }
  window.sessionStorage.setItem('GRADE_CALC_INFOTEXTS', JSON.stringify(infoTexts));
}
