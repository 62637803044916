import React, { useEffect, useState } from 'react';

import Accordion from '../accordion';

import { getFAQ } from '../../utils/texts';

import './styles.scss';


export default function Faq() {

  const [list, setList] = useState([]);
  useEffect(() => {
    (async () => setList(await getFAQ()))();
  },
  []);

  return list.map(({ title, body }, kid) => <Accordion key={ kid } children={ body } title={ title } />);
};


Faq.propTypes = {};
