import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router';
import styled from 'styled-components';

import ScoreRange from './score-range';
import StudyTable from './study-table';
import SectionList from './section-list';
import Breadcrumbs from './breadcrumbs';
import StudySearch from './search';
import StudySearchResults from './search-results';
import Requirements from '../requirements';

import { CtaLink } from '../helpers';

import { toTop } from '../../../utils/helpers';
import { getStudyRequirements } from '../../../utils/study-logic';
import { calculateRequirements, getDescribedRequirements } from '../../../utils/subject-logic';
import { getStudies } from '../../../utils/studies';


const AlignRight = styled.div`
  margin: 20px auto;
  text-align: right;
`;

const TwoCols = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
`;


function StudySelector({ subjectMap = {}, totals = {} }) {

  const [searchStr, setSearchStr] = useState('');

  const [studyPoints, setStudyPoints] = useState({});
  const [requirements, setRequirements] = useState({
    calculated: {}, described: {}, study: {},
  });

  useEffect(() => {
    setStudyPoints(totals);
  },
  [totals]);

  useEffect(() => {
    // Recalculate the requirements
    const calcReqs = calculateRequirements(subjectMap, studyPoints);
    const descReqs = getDescribedRequirements();
    const studyReqs = getStudyRequirements(calcReqs, descReqs);
    setRequirements({ calculated: calcReqs, described: descReqs, study: studyReqs });
  },
  [subjectMap, studyPoints]);

  const [sections, setSections] = useState({});
  const [matches, setMatches] = useState({ $: 0 });
  const [counts, setCounts] = useState({ $: 0 });

  useEffect(() => {
    if (!searchStr) {
      // Retrieve study sections, with counts and matches
      const newStudies = getStudies(false, false, requirements.study, studyPoints);
      setSections(newStudies.sections);
      setMatches(newStudies.matches);
      setCounts(newStudies.counts);
    }
  },
  [searchStr, requirements.study, studyPoints]);


  return (
    <section>
      <h1>Study Selector</h1>
      <TwoCols>
        <ScoreRange points={ studyPoints } setPoints={ setStudyPoints } />
        <StudySearch searchStr={ searchStr } setSearchStr={ setSearchStr } />
      </TwoCols>

      <Route path="/proto/study-selector/:interest?/:category?" render={ props =>
        <Breadcrumbs { ...props } setSearchStr={ setSearchStr } sections={ sections } />
      } />

      {searchStr ?
        <StudySearchResults
          query={ searchStr }
          requirements={ requirements }
          points={ studyPoints }
        /> :
        <Switch>
          <Route exact path="/proto/study-selector/:interest/:category" render={ props =>
            <StudyTable { ...props }
              requirements={ requirements } points={ studyPoints }
              matches={ matches } counts={ counts } sections={ sections } />
          } />
          <Route exact path="/proto/study-selector/:interest?" render={ props =>
            <SectionList { ...props }
              matches={ matches } counts={ counts } sections={ sections } />
          } />
        </Switch>
      }

      <AlignRight>
        <CtaLink to="/proto/grade-calculator" onClick={ toTop }>Gå til Karakterkalkulatoren</CtaLink>
      </AlignRight>

      <Requirements
        reqs={ requirements.calculated }
        position="A" title="Calculated Requirements"
      />
      <Requirements
        reqs={ requirements.study }
        position="D" title="Study Requirements (Kravkode)"
      />
    </section>
  );
}

export default StudySelector;
