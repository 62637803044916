import React from 'react';

import Subject from '../subject';
import Section from '../../section';

import {
  getSubjects,
  getLanguageSubject,
  getLanguageOptions,
  getSpecialSubject,
  getSpecialOptions,
  getGroupedSubjects,
} from '../../../utils/subjects';


const allSubjects = getSubjects();


export default function SubjectGroup({
  title,
  items,
  specialItems,
  languageItem,
  section,
  ...props
}) {

  const normalListItems = items
    ? Object.keys(items).map(k => allSubjects[k]).sort((a, b) => a.name > b.name ? 1 : -1)
    : [];

  const normalList = getGroupedSubjects(normalListItems);

  const specialListItems = specialItems
    ? Object.keys(specialItems).map(k => ({ id: k, ...specialItems[k] })).sort((a, b) => a.name > b.name ? 1 : -1)
    : [];

  const specialList = getGroupedSubjects(specialListItems);

  if (!normalList.length && !specialList.length && !languageItem) {
    return null;
  }

  const content = (
    <>
      <h3 className="text-xl font-normal mb-2xs">{title}</h3>
      {normalList.map(subj => (
        <Subject key={ subj.id } subject={ subj } value={ items[subj.id] } { ...props } />
      ))}

      {specialList.map(subj => (
        <Subject key={ subj.id }
          subject={ getSpecialSubject(subj) } value={ subj.value }
          selected={ subj.selected } options={ getSpecialOptions(subj.subjects) }
          { ...props }
        />
      ))}

      {languageItem &&
        <Subject
          subject={ getLanguageSubject() } value={ languageItem.value }
          selected={ languageItem.selected } options={ getLanguageOptions(languageItem.subjects) }
          { ...props }
        />
      }
    </>
  );

  return section
    ? <Section width="narrow" className="subject-grades-section">{content}</Section>
    : content;
}
