import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as CurvedButton } from '../../assets/curve.svg';
import { ReactComponent as ChevronDown } from '../../assets/chevron-down.svg';

import Tooltip from '../tooltip';
import { Button } from '@utdanningno/designsystem-react';

import { getHelpTexts } from '../../utils/texts';

import './styles.scss';


export default function Scoreboard({ above22, points = {}, showFAQ }) {

  const [schoolTip, setSchoolTip] = useState('');
  const [competeTip, setCompeteTip] = useState('');

  useEffect(() => {
    (async () => {
      const txt = await getHelpTexts();
      if (txt) {
        setSchoolTip(txt.skolepoeng || '');
        setCompeteTip(txt.konkurransepoeng || '');
      }
    })();
  }, []);


  const [scoreboardIsOpen, setScoreboardIsOpen] = useState(false);

  const above22Class = above22 ? 'is-above-22' : '';
  const isOpen = scoreboardIsOpen ? 'is-open' : 'is-closed';

  return (
    <div
      className={ ['scoreboard', above22Class, isOpen].join(' ') }
    >
      <button
        className="scoreboard__toggle"
        onClick={ () => setScoreboardIsOpen(!scoreboardIsOpen) }>
        <CurvedButton />
        <ChevronDown />
      </button>
      <div className="scoreboard__body">
        <div className="points-container">
          <div className="school-points">
            {!above22 && (
              <>
                <div className="scoreboard__summary">
                  <span>{points.school || 0}</span>
                  <Tooltip
                    title="Skole&shy;poeng"
                    description={ schoolTip }
                  >Skole&shy;poeng</Tooltip>
                </div>
                <div className="scoreboard__details">
                  <p>Dine skolepoeng (Førstegangsvitnemålskvote)</p>
                  <ul className="list-reset">
                    <li>
                      Karakterpoeng: <strong>{points.grade || 0} poeng</strong>
                    </li>
                    <li>
                      + Språk- og realfagspoeng: <strong>{points.credits || 0} poeng</strong>
                    </li>
                    <li>
                      <span className="underline-double">
                        = {points.school || 0} skolepoeng
                      </span>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </div>
          <div className="competition-points">
            <div className="scoreboard__summary">
              <span>{points.total || 0}</span>
              <Tooltip
                title="Konkurranse&shy;poeng"
                description={ competeTip }
              >Konkurranse&shy;poeng</Tooltip>
            </div>
            <div className="scoreboard__details">
              <p>Dine konkurransepoeng (Ordinær kvote)</p>
              <ul className="list-reset">
                <li>
                  Karakterpoeng: <strong>{points.grade || 0} poeng</strong>
                </li>
                <li>
                  + Språk- og realfagspoeng: <strong>{points.credits || 0} poeng</strong>
                </li>
                <li>
                  + Alderspoeng: <strong>{points.age || 0} poeng</strong>
                </li>
                <li>
                  + Tilleggspoeng: <strong>{points.extra || 0} poeng</strong>
                </li>
                <li>
                  <span className="underline-double">
                    = {points.total || 0} konkurransepoeng
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Button onClick={ showFAQ } chevron className="mt-lg lg-down">Ofte stilte spørsmål</Button>
      </div>
    </div>
  );
}


Scoreboard.propTypes = {
  above22: PropTypes.bool,
  points: PropTypes.object,
  showFAQ: PropTypes.func,
};

Scoreboard.defaultProps = {
  above22: false,
  points: {},
};
