import React from "react";
import PropTypes from 'prop-types';
import Input from "../../elements/input";

/**
 * @param value
 * @param className
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
export function TextInput({className, isInvalid, ...rest}) {

  const classes = [
    'text-input',
    isInvalid ? 'text-input--error' : ``,
    className,
  ].join(` `);

  return (
    <Input
      className={classes}
      {...rest}
    />
  );
}

TextInput.propTypes = {
  className: PropTypes.string,
};


export default TextInput;
