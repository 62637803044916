import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { ReactComponent as ChevronRight } from '../../assets/chevron-right.svg';

import './styles.scss';


export default function LinkPanel({
  to,
  title,
  studies = [],
  large,
  className,
  onClick,
}) {
  const studiesClass = large ? 'link-panel--studies-large' : 'link-panel--studies';

  return (
    <Link
      to={ to }
      className={ ['link-panel', studiesClass, className].join(' ').trim() }
      onClick={ onClick }
    >
      <div className="link-panel__copy">
        <span className="link-panel__name">{title}</span>
        <span className="link-panel__available-studies">
          <span className="highlight">{studies[0]}</span>
          {studies[1] !== undefined ? <span> av {studies[1]}</span> : null}
          <span> studier</span>
        </span>
      </div>
      <ChevronRight aria-hidden />
    </Link>
  );
}


LinkPanel.propTypes = {
  to: PropTypes.string,
  title: PropTypes.string,
  studies: PropTypes.arrayOf(PropTypes.number),
  className: PropTypes.string,
};

LinkPanel.defaultProps = {};
