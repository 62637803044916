import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import HistoryTable from './history-table';
import RequirementBreakdown from './req-breakdown';

import { filterKey, getOk } from '../../../utils/helpers';
import { getHistoricalData, meetsRequirements } from '../../../utils/studies';


const PaddedTable = styled.table`
  margin: 30px 0;
`;

const DarkRow = styled.tr`
  background-color: #EEE;
`;


export function StudyRow({ study, requirements, points }) {
  const { requires, first, ordinary } = study;
  const [okReq, okFirst, okOrdinary] = meetsRequirements(study, requirements.study, points);
  const okStudy = okFirst && okReq;

  const [open, setOpen] = useState(false);
  const [history, setHistory] = useState({});

  useEffect(() => setOpen(false), [study]);

  useEffect(() => {
    if (study && open) {
      setHistory(getHistoricalData(study.id));
    }
  },
  [study, open]);

  return (
    <>
      <tr onClick={ () => setOpen(!open) }>
        <td>
          <a href={ study.url }
            onClick={ ev => ev.stopPropagation() }
            target="_blank" rel="noopener noreferrer"
          >
            <h4>{study.title}</h4>
          </a>
          <small>{study.org}</small>
        </td>
        <td>{getOk(okStudy)}</td>
        <td>{getOk(okReq)} {requires}</td>
        <td>{getOk(okFirst)} {first || 'Alle'}</td>
        <td>{getOk(okOrdinary)} {ordinary || 'Alle'}</td>
      </tr>
      {open && <>
        <DarkRow>
          <td>
            <h4>Poenggrenser</h4>
          </td>
          <td colSpan="2">
            <h4>Siste poenggrenser på dette studiet:</h4>
            <p>{first || 'Alle'}</p>
            <h4>Dinne poeng:</h4>
            <p>{getOk(okFirst)} {points.school}</p>
          </td>
          <td colSpan="2">
            <h4>Skjul historiske poeggrenser</h4>
            {history && <HistoryTable data={ history } />}
          </td>
        </DarkRow>
        {requires &&
          <DarkRow>
            <td>
              <h4>Kravcode</h4>
            </td>
            <td colSpan="2">
              <h4>Disse fagene og karakterene må du ha:</h4>
              <p>{getOk(okReq)} {requires}</p>
            </td>
            <td colSpan="2">
              {requires && <RequirementBreakdown code={ requires } requirements={ requirements } />}
            </td>
          </DarkRow>
        }
      </>}
    </>
  );
}


export function StudyTableInner({ list, requirements, points }) {
  return (
    <PaddedTable>
      <thead>
        <tr>
          <th>Studie</th>
          <th>Fyller du kravene?</th>
          <th>Kravcode</th>
          <th>Førstegangs­vitnemål</th>
          <th>Ordinær kvote</th>
        </tr>
      </thead>
      <tbody>
        {list.map((study, i) => (
          <StudyRow key={ i }
            study={ study }
            requirements={ requirements }
            points={ points }
          />
        ))}
      </tbody>
    </PaddedTable>
  );
}


function StudyTable({ requirements, points, matches, counts, sections, match }) {

  const [interestName, setInterestName] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [studyList, setStudyList] = useState([]);

  useEffect(() => {
    const { interest, category } = match.params;

    // 3nd level -> Show table with studies
    const intName = filterKey(sections, interest);
    const catName = filterKey(sections[intName], category);

    setInterestName(intName);
    setCategoryName(catName);
    setStudyList(sections[intName][catName]);
  },
  [match.params, sections]);


  // Determine matches and totals
  const pageMatches = matches[interestName]?.[categoryName] || 0;
  const pageCounts = counts[interestName]?.[categoryName] || 0;


  return (
    <div>
      <h2><u>{categoryName}</u></h2>
      <span>{pageMatches} av {pageCounts} mulige studier</span>

      <StudyTableInner list={ studyList } requirements={ requirements } points={ points } />
    </div>
  );
}

export default StudyTable;
