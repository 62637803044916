import React from 'react';
// import PropTypes from 'prop-types';

import Radio from '../../../input/radio';


function OptionGroup(props) {
  return (
    <Radio unselect { ...props } />
  );
}


export function FailPassGood({ name, ...props }) {
  const options = [
    { label: 'Ikke bestått', value: 1 },
    { label: 'Bestått', value: 2 },
    { label: 'Bestått meget godt', value: 3 },
  ];

  return (
    <OptionGroup
      options={ options }
      name={ `fail-pass-good-${name}` }
      direction="vertical"
      { ...props } />
  );
}


export function FailPass({ name, ...props }) {
  const options = [
    { label: 'Ikke bestått', value: 1 },
    { label: 'Bestått', value: 2 },
  ];

  return (
    <OptionGroup
      options={ options }
      name={ `fail-pass-${name}` }
      direction="vertical"
      { ...props } />
  );
}


export function Participated({ name, ...props }) {
  const options = [
    { label: 'Ikke Deltatt', value: 1 },
    { label: 'Deltatt', value: 2 },
  ];

  return (
    <OptionGroup
      options={ options }
      name={ `participated-${name}` }
      direction="vertical"
      { ...props } />
  );
}
