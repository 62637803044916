import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';

import Section from '../../section';
import Breadcrumb from '../breadcrumb';
import Breadbutton from '../breadcrumb/button';
import Study from '../study';
import EmptyLinkPanel from '../../link-panel/empty';

import { filterKey } from '../../../utils/helpers';
import { allSections } from '../../../utils/studies';
import { Checkbox } from '@utdanningno/designsystem-react';

import './styles.scss';


export default function StudyList({
  requirements,
  points,
  above22,
  sections = {},
  matches,
  counts,
  showOnlyMatches,
  setShowOnlyMatches,
  loading,
}) {

  const routeMatch = useRouteMatch();
  const [interestName, setInterestName] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [list, setList] = useState([]);


  useEffect(() => {
    const { interest, category } = routeMatch.params;

    // 3nd level -> Show table with studies
    const intName = filterKey(allSections, interest);
    const catName = filterKey(allSections[intName], category);

    setInterestName(intName);
    setCategoryName(catName);
    setList(sections[intName]?.[catName] || []);
  },
  [routeMatch.params, sections]);


  // Determine matches and totals
  const pageMatches = matches[interestName]?.[categoryName] || 0;
  const pageCounts = counts[interestName]?.[categoryName] || 0;

  return (
    <Section>
      <Breadcrumb className="mt-xl" match={ routeMatch } />
      <Breadbutton className="mt-sm" match={ routeMatch } />

      <Checkbox
        label="Vis kun studier jeg sannsynligvis kommer inn på"
        name="show-only-availabe-2"
        checked={ showOnlyMatches }
        onChange={ () => setShowOnlyMatches(!showOnlyMatches) }
      />

      <p className="mt-sm text-lg">
        <span className="font-bold">{pageMatches}</span>
        {!showOnlyMatches ? <span> av <span className="font-bold">{pageCounts}</span></span> : null}
        <span> studier</span>
      </p>

      <div className="study-list mt-lg">
        <h2 className="study-list__title">
          <span className="primary">{interestName}:</span>
          <span className="sub">{categoryName}</span>
        </h2>

        <ul className="list-reset">
          {list.map((study, i) => (
            <li className="study-list__item" key={ i } >
              <Study
                study={ study }
                requirements={ requirements } points={ points }
                showOnlyMatches={ showOnlyMatches }
                above22={ above22 }
              />
            </li>
          ))}
        </ul>

        {loading && <p className="mt-lg">Henter studier...</p>}
        {!loading && (!pageCounts || (showOnlyMatches && !pageMatches)) && <EmptyLinkPanel />}
      </div>
    </Section>
  );
}
