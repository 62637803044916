import { useEffect } from 'react';
import ReactGA from 'react-ga4';

const TRACKING_ID = 'G-G8RW07ND34';
const enableDebug = process.env?.NODE_ENV === 'development';

export default function useGoogleAnalytics() {

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID, { debug: enableDebug });
    //ReactGA.send('pageview');
  });

}
