import React from 'react';
import parse from 'html-react-parser';

function Disclaimer({ disclaimerText, showDisclaimer }) {
  if (showDisclaimer) {
    return <p className="disclaimer">{parse(disclaimerText)}</p>;
  }

  return null;
}

export default Disclaimer;
