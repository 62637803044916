import React from 'react';
import PropTypes from 'prop-types';

export default function RadioItem({
  label,
  id,
  value,
  name,
  selected,
  setRadio,
  setRadioReset,
  unselect,
  ...props
}) {

  function handleChange(e) {
    const newValue = e.target.value;
    return setRadio?.(newValue);
  }

  function handleClick(e) {
    if (unselect && selected?.toString() === e.target.value) {
      return setRadioReset?.(e.target.value);
    }
  }

  function handleKeyDown(e) {
    if (unselect && e.keyCode === 32 && selected?.toString() === e.target.value) {
      e.preventDefault();
      return setRadioReset?.(e.target.value);
    }
  }

  return (
    <>
      <input
        type="radio"
        name={ name }
        id={ id }
        checked={ value === selected }
        value={ value }
        onClick={ handleClick }
        onKeyDown={ handleKeyDown }
        onChange={ handleChange }
        disabled={ props.disabled }
        { ...props }
      />
      <label
        htmlFor={ id }
      >
        <span className="input-radio__copy">{label}</span>
      </label>
    </>
  );
}

RadioItem.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  name: PropTypes.string.isRequired,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  setRadio: PropTypes.func,
  setRadioReset: PropTypes.func,
  unselect: PropTypes.bool,
};
