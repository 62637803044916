import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as IconQuestionCircle } from '../../assets/info-circle.svg';
import { ReactComponent as IconCross } from '../../assets/cross-small.svg';

import './styles.scss';


export default function Infobox({ title, description, onClose }) {

  const [showInfobox, setShowInfobox] = useState(true);

  const handleToggle = () => {
    setShowInfobox(false);
    return onClose?.();
  };

  if (!showInfobox) {
    return null;
  }
  return (
    <div className="infobox">
      <div className="infobox__header">
        <IconQuestionCircle className="mr-xs infobox__icon" /> {title}
        <button
          className="infobox__close"
          onClick={ () => handleToggle() }
        >
          <span className="visuallyhidden">Lukk {title}</span>
          <IconCross />
        </button>
      </div>
      {description && <div dangerouslySetInnerHTML={ { __html: description } } />}
    </div>
  );
}

Infobox.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

Infobox.defaultProps = {};
