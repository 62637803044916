import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import ExpandableField from '../../expandable-field';
import Input from '../../input/input';
import Infobox from '../../infobox';

import { getAdditionalPoints } from '../../../utils/programs';
import { getDismissed, getHelpTexts, setDismissed } from '../../../utils/texts';
import { isYobBefore1989 } from '../../../utils/helpers';
import { Checkbox } from '@utdanningno/designsystem-react';

import './styles.scss';


const pointsOptions = getAdditionalPoints();


export default function EditAdditionalPoints({
  yob,
  addPoints = {},
  setYob,
  setAddPoints,
  totals,
  ...props
}) {

  // Tooltips and help texts
  const ref = useRef();
  const [before1989Info, setBefore1989Info] = useState('');

  useEffect(() => {
    ref.current = true;
    (async () => {
      const txt = await getHelpTexts();
      if (txt && ref.current) {
        setBefore1989Info(txt.before_1989 || '');
      }
    })();
    return () => ref.current = false;
  }, []);


  // Year of birth
  const [yearOfBirth, setYearOfBirth] = useState(yob);

  useEffect(() => {
    if (yob && !yearOfBirth) {
      setYearOfBirth(yob);
    }
  },
  [yob, yearOfBirth]);

  useEffect(() => {
    if (parseInt(yearOfBirth) > 1920) {
      return setYob(yearOfBirth);
    }
    setYob('');
  },
  [yearOfBirth, setYob]);


  // Born before 1989 info texts
  const onBefore1989InfoClose = () => {
    setDismissed('before_1989', true);
  };
  const isBefore1989 = isYobBefore1989(yearOfBirth);
  const showBefore1989Info = isBefore1989 && !getDismissed('before_1989');


  // Additional Points
  const onAddPointsCheck = (name, value) => {
    const newAddPoints = { ...addPoints };
    newAddPoints[name] = value;
    return setAddPoints(newAddPoints);
  };


  return (
    <ExpandableField
      label="Vis dine valgte tilleggspoeng"
      labelOpen="Skjul dine valgte tilleggspoeng"
      className="edit-additional-points"
      { ...props }
    >
      <div className="edit-additional-points__yob">
        <Input
          label="Jeg er født i"
          type="number" maxLength="4" minLength="4" size="4"
          name="year-of-birth"
          placeholder="2000"
          value={ yearOfBirth }
          setValue={ setYearOfBirth }
        />
        <span className="edit-additional-points__points">Alderspoeng +{totals.age}</span>
      </div>

      {showBefore1989Info &&
        <Infobox title="Obs!" description={ before1989Info } onClose={ onBefore1989InfoClose } />
      }

      <div className="edit-additional-points__checklist">
        {pointsOptions.map((opt, i) => (
          <Checkbox key={ i }
            label={ opt.name }
            name={ opt.id }
            checked={ addPoints[opt.id] }
            onChange={ () => onAddPointsCheck(opt.id, !addPoints[opt.id]) }
          />
        ))}
      </div>
      <p className="text-right">Tilleggspoeng +{totals.extra}</p>
      <p className="text-dark-gray-2 text-right">Du kan få maks {totals.age + totals.extra} tilleggspoeng</p>

    </ExpandableField>
  );
}


EditAdditionalPoints.propTypes = {
  yob: PropTypes.string,
  addPoints: PropTypes.object,
  setYob: PropTypes.func,
  setAddPoints: PropTypes.func,
  totals: PropTypes.object,
};

EditAdditionalPoints.defaultProps = {
  yob: '',
  addPoints: {},
  setYob: () => { },
  setAddPoints: () => { },
  totals: {},
};
