import React from 'react';
import { getSubjectCode } from '../../../utils/subjects';


export default function RemoveSubject({
  subject,
  removeSubject,
  setRemoveConfirm,
  selected,
  options,
}) {
  const { id, name, section, children } = subject;
  return (
    <>
      <div className="subject__header">
        <span className="subject__id">Fagkode: {getSubjectCode(options, selected, id, children)}</span>
        <div className="remove__buttons">
          <button
            type="button"
            className="remove__cancel"
            onClick={ () => setRemoveConfirm(false) }
          >
            Avbryt
          </button>
          <button
            type="button"
            className="remove__confirm"
            onClick={ () => { setRemoveConfirm(false); removeSubject(section, children ? [id, ...children.map(c => c.id)] : [id]); } }
          >
            Fjern
          </button>
        </div>
      </div>
      <h4 className="subject__name">{name}</h4>
    </>
  );
}
