import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@utdanningno/designsystem-react';

export default function ContinueToLogin({ onClick }) {
  return (
    <>
      <h2>Lagre dine karakterer</h2>
      <p>
        {
          !window.isLoggedIn
            ? 'Logg inn eller opprett bruker her på utdanning.no for å lagre karakterene dine.'
            : ''
        }
      </p>
      <Button onClick={ onClick }>
        {window.isLoggedIn ? 'Lagre' : 'Logg inn og lagre'}
      </Button>
    </>
  );
}


ContinueToLogin.propTypes = {
  onClick: PropTypes.func.isRequired,
};
