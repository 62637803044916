import React from 'react';

import SubjectTable from './subject-table';

import allPrograms from '../../../data/programs.json';


function SubjectMap({ program, subjectMap, ...props }) {
  if (!program) {
    return null;
  }

  const [prog, sub] = program;
  const p1 = allPrograms[prog];
  const p2 = allPrograms[prog].programs[sub];
  const title = `${p1.name}${p1.skip !== sub ? `, med ${p2.name}` : ''}`;

  return (
    <section>
      <h2><u>{title}</u></h2>
      <SubjectTable
        title="Fellesfag"
        items={ subjectMap.common }
        specialItems={ subjectMap.special }
        languageItem={ subjectMap.language }
        section="common"
        { ...props }
      />
      <SubjectTable
        title="Felles programfag"
        items={ subjectMap.commonProgram }
        section="commonProgram"
        { ...props }
      />
      <SubjectTable
        title="Valgfrie programfag"
        items={ subjectMap.optionalProgram }
        section="optionalProgram"
        { ...props }
      />
      <SubjectTable
        title="Yrkesfaglig fordypning"
        items={ subjectMap.vocational }
        section="vocational"
        { ...props }
      />
    </section>
  );
}

export default SubjectMap;
