import React, { useState } from 'react';
// import PropTypes from 'prop-types';

import Radio from '../../input/radio';
import Section from '../../section';
import SubjectGroup from './group';

import './styles.scss';


export default function SubjectGrades({ subjectMap, ...props }) {

  const [collapsed, setCollapsed] = useState(false);
  const [activeSubject, setActiveSubject] = useState('');

  const subjProps = {
    collapsed,
    activeSubject,
    setActiveSubject,
    ...props,
  };

  return (
    <>
      <Section width="narrow" className="subject-grades-section">
        <p className="subject-display__label">Bytt visning</p>
        <Radio
          className="subject-display__input"
          name="subject-display"
          selected={ collapsed ? 'minimized' : 'maximized' }
          setRadio={ value => setCollapsed(value === 'minimized') }
          options={ [
            { label: 'Ekspander visning', value: 'maximized' },
            { label: 'Komprimer visning', value: 'minimized' },
          ] }
        />

        <h2>Legg til karakterer</h2>
        <SubjectGroup
          title="Fellesfag"
          items={ subjectMap.common }
          specialItems={ subjectMap.special }
          languageItem={ subjectMap.language }
          { ...subjProps }
        />
      </Section>

      <SubjectGroup
        title="Felles programfag"
        items={ subjectMap.commonProgram }
        section={ true }
        { ...subjProps }
      />

      <SubjectGroup
        title="Valgfrie programfag"
        items={ subjectMap.optionalProgram }
        section={ true }
        { ...subjProps }
      />

      <SubjectGroup
        title="FYrkesfaglig fordypning"
        items={ subjectMap.vocational }
        section={ true }
        { ...subjProps }
      />
    </>
  );
}
