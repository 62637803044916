/**
 * Studies
 * @module utils/studies
 */

import axios from 'axios';

import studies from '../data/studies.json';
import historicalData from '../data/historical-data.json';
import facets from '../data/study-facets.json';
import $ from 'jquery';


// Add extra facet for study type (studieform)
const studyTypes = ['Deltid', 'Nettbasert', 'Samlingsbasert'];
facets.studieform = studyTypes.map(name => ({ name, count: 0 }));


export const allSections = getStudies().sections;


// API


/**
 * Checks if a study meets a set of filters for location and level of study
 *
 * @param {object} study the study
 * @param {object} filters an object with a set of predefined filters
 * @returns {boolean} indicates whether the study matches or not
 */
function meetsFilters(study, filters) {
  if (!filters) {
    return true;
  }

  let valid = true;

  // Level of study
  if (valid && filters.level) {
    const levels = Object.keys(filters.level).filter(k => filters.level[k]);
    const okLevel = levels.includes(study.level);
    valid = okLevel;
  }

  // Location of the university
  if (valid && filters.location) {
    const locations = Object.keys(filters.location).filter(k => filters.location[k]);
    const okLoc = study.location.reduce((ok, loc) => ok || locations.includes(loc), false);
    valid = okLoc;
  }

  // Type of study
  if (valid && filters.type) {
    const types = Object.keys(filters.type).map(t => studyTypes.indexOf(t));
    const okType = types.reduce((ok, idx) => ok || study.type[idx], false);
    valid = okType;
  }

  return valid;
}


/**
 *  Checks if a study matches a text string. Looks in the id, title, organization and requirement code.
 *
 * @param {object} study the study
 * @param {string} search the search string
 * @returns {boolean} indicates whether the study matches or not
 */
function meetsSearch(study, search) {
  if (!search) {
    return true;
  }
  const xstr = search.toLowerCase();
  return (study.id && study.id.toLowerCase().includes(xstr)) ||
    (study.title && study.title.toLowerCase().includes(xstr)) ||
    (study.org && study.org.toLowerCase().includes(xstr)) ||
    (study.requires && study.requires.toLowerCase().includes(xstr));
}


/**
 * Returns a list of studies by section
 *
 * @param {string} search a search string
 * @param {object} filters an object with a set of predefined filters
 * @param {object} requirements set of study requirements
 * @param {object} points set of study points
 * @param {array} list optional study source list
 * @returns {object}
 */
export function getStudies(search, filters, requirements, points, list = studies) {
  list = search ? list.filter(s => meetsSearch(s, search)) : list;
  list = filters ? list.filter(s => meetsFilters(s, filters)) : list;

  const sections = {};
  const counts = { $: list.length };
  const matches = { $: 0 };

  const checkReq = requirements && points && true;

  // Group the studies into lists by interest and category
  list.forEach(study => {
    // Set defaults for interest and category
    if (!study.interest.length) {
      study.interest.push('_');
    }
    if (!study.category.length) {
      study.category.push('_');
    }

    const meetsReq = checkReq && meetsRequirements(study, requirements, points);
    const okReq = meetsReq && meetsReq[0] && meetsReq[1];
    if (okReq) {
      matches.$++;
    }

    // Build a tree with interests > categories (plus counts and matches)
    study.interest.forEach(int => {
      sections[int] = sections[int] || {};
      counts[int] = counts[int] || { $: 0 };
      counts[int].$++;

      if (okReq) {
        matches[int] = matches[int] || { $: 0 };
        matches[int].$++;
      }

      study.category.forEach(cat => {
        sections[int][cat] = sections[int][cat] || [];
        sections[int][cat].push(study);

        counts[int][cat] = counts[int][cat] || 0;
        counts[int][cat]++;

        if (okReq) {
          matches[int][cat] = matches[int][cat] || 0;
          matches[int][cat]++;
        }
      });
    });
  });

  return { sections, counts, matches };
}


/**
 * @deprecated
 * Searches the studies for a given string.
 * Checks for matches in the study title, organization, level and requirement code.
 *
 * @param {string} str the input string
 * @param {object} filter additional filters for level and location
 * @returns {array} a list of matches studies
 */
export function searchStudies(str, filter) {
  const res = Object.values(studies).filter(s => {
    return meetsSearch(s, str) && (!filter || meetsFilters(s, filter));
  });
  return res;
}


/**
 * Filters studies by matching requirements and school points
 *
 * @param {object} requirements the calculated requirements
 * @param {object} points the calculated points
 * @param {array} pool pool of studies to search in (defaults to all studies)
 * @returns {array} the list of matched studies
 */
function filterStudiesByRequirements(requirements, points, pool = studies) {
  return pool.filter(study => {
    const [okReq, okFirst] = meetsRequirements(study, requirements, points);
    return okReq && okFirst;
  });
}


/**
 * Returns the number of study that matches the requirements / points.
 * The level parameter determines how the counts are broken down.
 *    level 0 - no breakdown, only totals
 *    level 1 - breakdown by interest
 *    level 2 - breakdown by interest and category
 *
 * @param {object} requirements the calculated requirements
 * @param {object} points the calculated points
 * @param {number} level the level of breakdown
 * @param {array} pool pool of studies to search in (defaults to all studies)
 * @returns {object} a structure with the count of matches
 */
export function countMatchingStudies(requirements, points, level = 0, pool = studies) {
  const list = filterStudiesByRequirements(requirements, points, pool);
  const res = {
    $: list.length,
  };

  if (level) {
    list.forEach(study => {
      study.interest.forEach(int => {
        if (level === 1) {
          res[int] = res[int] || 0;
          res[int]++;
        }
        else if (level === 2) {
          res[int] = res[int] || { $: 0 };
          res[int].$++;

          study.category.forEach(cat => {
            res[int][cat] = res[int][cat] || 0;
            res[int][cat]++;
          });
        }
      });
    });
  }
  return res;
}


/**
 * Checks if a study meets the requirements.
 * It returns an array with each part is matched:
 *    [0] - meets the study requirement code
 *    [1] - meets the school points (first)
 *    [2] - meets the competition points (ordinary)
 *
 * @param {objec} study the study
 * @param {object} requirements the calculated requirements
 * @param {object} points the calculated points
 * @returns {array} the match result
 */
export function meetsRequirements(study, requirements, { school, total }) {
  const { requires, first, ordinary } = study;
  const okReq = requirements[requires] === null ? null : requirements[requires];
  const okFirst = (typeof first === 'number' && first >= 0) ? first <= school : null;
  const okOrdinary = (typeof ordinary === 'number' && ordinary >= 0) ? ordinary <= total : null;

  return [okReq, okFirst, okOrdinary];
}


/**
 * Returns an object with the historical data for a given study.
 * Each of the object keys represents a year.
 * This data is retrieved from the utdanning.no API
 * Source: https://api.utdanning.no/karakterkalkulator/points?filename=poenggrenser_history_per_code.json
 *
 * @param {string} studyId the study id
 * @returns {object|null} the historical data
 */
export function getHistoricalData(studyId) {
  return historicalData[studyId] || null;
}


/**
 * Returns a key-value objects with facets to be used in the study search
 *
 * @returns {object} the facets
 */
export function getStudyFacets() {
  return facets;
}

const rtrim = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g;

function trim(text) {
  return text == null ?
    '' :
    (text + '').replace(rtrim, '');
}

// Set cookie
function uno_minside_set_cookie(name, data, is_delete) {
  let expire = '';
  if (name === 'uno_redirect') {
    const now = new Date();
    const time = now.getTime();
    const expireTime = time + 1000 * 300; // 5 minutes.
    now.setTime(expireTime);
    expire = 'expires=' + now.toGMTString() + ';';
  }
  if (is_delete) {
    expire = 'expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }
  name = uno_minside_get_environment('_') + name;
  document.cookie = name + '=' + data + '; domain=.utdanning.no; path=/;' + expire + ' SameSite=None; Secure';
}

// Get cookie
function uno_minside_get_cookie(name) {
  name = uno_minside_get_environment('_') + name;
  name += '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    const c = trim(ca[i]);
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

// Returns environment (alfa, beta or prod)
function uno_minside_get_environment(suffix) {
  const srv = window.location.href.replace('http://', '').replace('https://', '').split('.');
  if (srv[0] === 'utdanning' || srv[0] === 'min') {
    // Prod (no suffix).
    return '';
  }

  if (srv[0] === 'beta') {
    return 'beta' + suffix;
  }
  else if (srv[0] === 'dev') {
    return 'dev' + suffix;
  }

  return '';
  // return srv[0] + suffix;
}

// Get user info for minside-widget.
function uno_minside_get_user_intro(nr_of_tries) {
  uno_minside_get_favs();
  $.ajax({
    url: uno_minside_get_server_name() + '/user_login',
    type: 'POST',
    xhrFields: {
      withCredentials: true,
    },
    success: function (data) {
      $(data[0].selector).html(data[0].data);
      uno_minside_bind_favorites();
    },
    error: function (data) {
      let milliseconds = 0;
      $('.col-user-menu').html('Beklager, kan ikke opprette kontakt med vår innloggingsserver. Vennligst prøv igjen senere. Ta kontakt med oss hvis problemet vedvarer.');
      if (nr_of_tries > 0) {
        milliseconds = 2000;
        console.log('Retrying..');
      }
      else if (nr_of_tries > -5) {
        milliseconds = 10000;
        console.log('Retrying slowly..');
      }
      else {
        console.log('Ending..');
        return;
      }
      setTimeout(function () {
        uno_minside_get_user_intro(--nr_of_tries);
      }, milliseconds);
    },
  });
}

// Reverse add .
function uno_minside_reverse_add(nid) {
  console.log('Error: Cannot add favorite');
  const data = decodeURI(uno_minside_get_cookie('uno_favs'));
  const favs = data.split('*');
  let new_favs = '';
  for (let i = 0; i < favs.length; i++) {
    if (favs[i] !== nid && favs[i] !== '') {
      new_favs += '*' + favs[i];
    }
  }
  uno_minside_set_cookie('uno_favs', new_favs, false);
}

// Reverse remove.
function uno_minside_reverse_remove(nid) {
  console.log('Error: Cannot remove favorite');
  const data = decodeURI(uno_minside_get_cookie('uno_favs'));
  let uno_favs = nid;
  if (data !== '') {
    uno_favs = nid + '*' + data;
  }
  uno_minside_set_cookie('uno_favs', uno_favs, false);
}

// Get favorites.
function uno_minside_get_favs() {
  let data = decodeURI(uno_minside_get_cookie('uno_favs'));
  data = data.split('*');
  const favs = [];
  for (let i = 0; i < data.length; i++) {
    if (data[i]) {
      favs.push(data[i]);
    }
  }
  if (favs.length > 0) {
    $('.col-sammenlign-min-side .minside-menu').html('Min side <span class="favs_number">' + favs.length + '</span>');
    // $('.uno_min_side_favs_wrapper h3 .fav-group').html(' (' + favs.length + ')');
  }
  else {
    $('.favs_number').hide();
    // $('.uno_minside_favs_wrapper').html('<br>');
  }
}

// Returns minside-server (alfa, beta or prod).
function uno_minside_get_server_name() {
  // Default protocol.
  let protocol = 'http://';
  const env = uno_minside_get_environment('.');
  if (env === '' || env === 'beta.') {
    protocol = 'https://';
  }
  return protocol + env + 'min.utdanning.no';
}

// Bind toggle to all favorite
function uno_minside_bind_favorites() {

  // Favorite meny
  $('.uno_min_side_favs_wrapper .uno_favoritt_remove').each(function () {
    $(this).click(function () {
      $(this).addClass('favs-processed');
      if ($('.icon-lagre-i-favoritter:not(.favs-processed)').hasClass('active')) {
        $('.icon-lagre-i-favoritter').toggleClass('active');
        $('.icon-lagre-i-favoritter .image-favourite-icon path').toggleClass('cls-1');
      }
      $(this).parent().parent().parent().remove();
      removeFavouriteFromApi($(this).parent().attr('id'));
    });
  });

  // min.utdanning.no forside
  if ($('.favs-wrapper .uno_favoritt_remove').length > 0) {
    $('.favs-wrapper .uno_favoritt_remove:not(.favs2-processed)').each(function () {
      $(this).addClass('favs2-processed');
      $(this).click(function () {
        $(this).parent().parent().parent().parent()[0].children[0].children[0].innerHTML = parseInt($(this).parent().parent().parent().parent()[0].children[0].children[0].innerHTML) - 1;
        $(this).parent().parent().parent().remove();
        removeFavouriteFromApi($(this).parent().attr('id'));
      });
    });
  }
}


// Favorite studies

const FAVS = decodeURI(uno_minside_get_cookie('uno_favs'));
const favorites = FAVS.split('*');
// const FAVS = window.localStorage.getItem('GRADE_CALC_FAVS');
// const favorites = (FAVS && JSON.parse(FAVS)) || [];


/**
 * Checks if study id exists in the favorite list
 *
 * @param {string} studyId the id of the study
 * @returns {boolean} true if the study exists in the list, or false otherwise
 */
export function isFavorite(studyId) {
  const idx = favorites.indexOf(studyId);
  return idx >= 0;
}


/**
 * Adds or removes a study to or from the favorites.
 * If the value is true, adds to favorites.
 * If the value is false, removes from favorites
 *
 * @param {string} studyId the id of the study
 * @param {boolean} value the value
 */
export function setFavorite(studyId, value) {
  const idx = favorites.indexOf(studyId);
  if (value && idx < 0) {
    addFavouriteToApi(studyId);
    favorites.push(studyId);
  }
  else if (!value && idx >= 0) {
    removeFavouriteFromApi(studyId);
    favorites.splice(idx, 1);
  }
  const uno_favs = favorites.join('*');
  uno_minside_set_cookie('uno_favs', uno_favs, false);
  // window.localStorage.setItem('GRADE_CALC_FAVS', JSON.stringify(favorites));
}

function addFavouriteToApi(studyId) {
  const query = `${urlStart()}min.utdanning.no/favorite/ajax_add/${studyId}`;
  axios({
    method: 'POST',
    url: query,
    withCredentials: true,
    headers: {
      'content-type': 'application/json',
    },
  })
  .then(res => {
    if (res.data === 'jaa') {
      // console.log('Favorite saved successfully');
      uno_minside_get_user_intro(1);
    }
    else {
      uno_minside_reverse_add(studyId);
    }
    // dispatch({ type: 'ADD_TO_FAVOURITES', payload: studyId });
  })
  .catch(err => {
    console.log(err);
    uno_minside_reverse_add(studyId);
    alert('Noe gikk galt med lagring...');
  });
}

function removeFavouriteFromApi(studyId) {
  const query = `${urlStart()}min.utdanning.no/favorite/ajax_remove/${studyId}`;
  axios({
    method: 'POST',
    url: query,
    withCredentials: true,
    headers: {
      'content-type': 'application/json',
    },
  })
  .then(res => {
    if (res.data === 'jaa') {
      // console.log('Favorite saved successfully');
      uno_minside_get_user_intro(1);
    }
    else {
      uno_minside_reverse_remove(studyId);
    }
    // dispatch({ type: 'REMOVE_FROM_FAVOURITES', payload: studyId });
  })
  .catch(err => {
    console.log(err);
    uno_minside_reverse_remove(studyId);
    alert('Noe gikk galt med lagring...');
  });
}

function urlStart() {
  const parts = window.location.hostname.split('.');
  if (parts[0] === 'utdanning' || parts[0] === 'min') {
    return 'https://';
  }
  if (parts[0] === 'beta') {
    return `https://${parts[0]}.`;
  }
  if (parts[0] === 'dev' || parts[0] === 'dev8') {
    return `http://${parts[0]}.`;
  }
}

export function formatPoeng(poeng) {
  if (typeof poeng === 'number' && poeng > 0) {
    return poeng;
  }
  if (parseFloat(poeng) && parseFloat(poeng) > 0) {
    // poeng er et tall over 0 i tekstformat
    return Number(poeng);
  }
  if (parseFloat(poeng) && !(parseFloat(poeng) > 0) &&
    parseFloat(poeng) === parseInt(poeng)
  ) {
    // poeng er et heltall mindre enn eller lik 0 i tekstformat
    poeng = Number.parseInt(poeng);
  }
  // Hvis vi er her så er poeng en av disse:
  // - et tall som er mindre enn eller lik 0
  // - et tall i tekstformat som er mindre enn eller lik 0
  // - en tekst
  // Vi har spesialregler for enkelte heltall mindre enn 0
  if (Number.isInteger(poeng)) {
    switch (poeng) {
      case 0:
        return 'Alle';
      case -1:
        return 'Avlyst';
      case -2:
        return 'o.i';
      case -3:
        return 'Ingen opptak';
      default: // poeng er et annet negativt tall vi ikke har spesifisert
        return poeng;
    }
  }
  else {
    // poeng er en tekst eller et tall i tekstformat som ikke matcher våre spesialregler. Antar at det beste er å returnere verdien som den er.
    return poeng;
  }
}

// Skal returnere en setning f.eks. 'Alle søkere kom inn' basert på output fra formatPoeng-funksjonen ovenfor
export function formatPoengText(poengTxt) {
  switch (poengTxt) {
    case 'Alle':
      return 'Alle søkere kom inn';
    default:
      return poengTxt;
  }
}
