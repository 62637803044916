import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CollapsedSubject from './collapsed';
import ExpandedSubject from './expanded';
import RemoveSubject from './remove';

import './styles.scss';


export default function Subject({
  subject,
  value,
  collapsed,
  activeSubject,
  setActiveSubject,
  setScore,
  selected,
  ...props
}) {
  const [removeConfirm, setRemoveConfirm] = useState(false);

  const { id, type, section } = subject;
  const isActiveSubject = id === activeSubject;

  const collapsedClass = !isActiveSubject && collapsed ? 'subject--collapsed' : '';
  const classType = type === 1 || type === 2 ? 'subject--grade' : 'subject--option';
  const classRemove = removeConfirm ? 'subject--remove' : '';

  const setValue = (kind, newValue) => {
    setScore(section, id, kind, parseInt(newValue) || null);
  };

  return (
    <div
      id={ `subject-${id}` }
      className={ ['subject', collapsedClass, classType, classRemove].join(' ') }
    >
      {!collapsed || isActiveSubject
        ? (!removeConfirm ?
          <ExpandedSubject
            subject={ subject }
            selected={ selected }
            value={ value }
            setValue={ setValue }
            setRemoveConfirm={ setRemoveConfirm }
            { ...props }
          /> :
          <RemoveSubject
            subject={ subject }
            setRemoveConfirm={ setRemoveConfirm }
            { ...props }
          />)
        : (
          <CollapsedSubject
            subject={ subject }
            value={ value }
            setActiveSubject={ setActiveSubject }
          />
        )}
    </div>
  );
}


Subject.propTypes = {
  subject: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.number,
  }),
  value: PropTypes.array,
  collapsed: PropTypes.bool,
  activeSubject: PropTypes.string,
  setActiveSubject: PropTypes.func,
  setScore: PropTypes.func,
};
