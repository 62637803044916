import React from 'react';
import styled from 'styled-components';


const positions = {
  A: '0;',
  B: '180px;',
  C: 'calc(100% - 360px);',
  D: 'calc(100% - 180px);',
};

const Sidebar = styled.div`
  position: fixed;
  z-index: 4;
  top: 0;
  left: ${props => `${positions[props.pos || 'A']}`}
  width: 180px;
  max-height: 100%;
  overflow: auto;
  padding: 10px 20px;

  color: white;
  background-color: #555;
`;

const SidebarTitle = styled.h2`
  margin-top: 0;
  color: white;
  font-size: 20px;
`;

const Value = styled.span`
  color: ${({ value }) => value ? (typeof value === 'number' ? '#0CF' : '#0C0') : '#FFF'};
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  li {
    list-style: none;
  }
`;


function Requirements({ reqs, position, title }) {
  const list = reqs ? Object.keys(reqs) : [];

  return (
    <Sidebar pos={ position }>
      <SidebarTitle>{title}</SidebarTitle>
      <List>
        {list.map((k, i) => (
          <li key={ i }>
            <Value value={ reqs[k] }>
              <strong>{k}:</strong> {reqs[k] === null ? 'null' : reqs[k].toString()}
            </Value>
          </li>
        ))}
      </List>
    </Sidebar>
  );
}

export default Requirements;
