import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import Section from '../../components/section';
import EditAdditionalPoints from '../../components/calculator/edit-additional-points';
import EditProgramPath from '../../components/calculator/edit-program-path';
import SubjectGrades from '../../components/calculator/subject-grades';
import SubjectSearch from '../../components/calculator/subject-search';
import ContinueToSelector from '../../components/calculator/continue-to-selector';
import ContinueToLogin from '../../components/calculator/continue-to-login';

import { calculateRequirements, getDescribedRequirements } from '../../utils/subject-logic';
import { getStudyRequirements } from '../../utils/study-logic';
import { countMatchingStudies } from '../../utils/studies';
import { toTop } from '../../utils/helpers';


export default function Calculator({
  program,
  chooseProgram,
  addPoints,
  setAddPoints,
  yob,
  setYob,
  subjectMap,
  addSubject,
  removeSubject,
  chooseSubject,
  setScore,
  totals,
}) {

  const history = useHistory();
  const [numMatches, setNumMatches] = useState(0);

  useEffect(() => {
    // Calculate requirements and matches
    const calc = calculateRequirements(subjectMap, totals);
    const desc = getDescribedRequirements();
    const study = getStudyRequirements(calc, desc);

    const newNumMatches = countMatchingStudies(study, totals);
    setNumMatches(newNumMatches.$);
  },
  [subjectMap, totals]);


  // Other actions

  const showStudySelector = () => {
    history.push('/studievelger');
    toTop();
  };

  const goToLogin = () => {
    const form = document.createElement('form');
    form.method = 'post';
    if (window.location.hostname.indexOf('dev8.') !== -1) {
      form.action = 'http://dev8.min.utdanning.no/karakterkalkulator/lagre';
    }
    else if (window.location.hostname.indexOf('dev.') !== -1) {
      form.action = 'http://dev.min.utdanning.no/karakterkalkulator/lagre';
    }
    else if (window.location.hostname.indexOf('beta.') !== -1) {
      form.action = 'https://beta.min.utdanning.no/karakterkalkulator/lagre';
    }
    else {
      form.action = 'https://min.utdanning.no/karakterkalkulator/lagre';
    }

    const input = document.createElement('input');
    input.name = 'data';
    input.type = 'hidden';
    input.value = sessionStorage.getItem('GRADE_CALC');

    form.appendChild(input);
    document.body.appendChild(form);
    form.submit();
  };


  return (
    <>
      <Section width="narrow">
        <EditAdditionalPoints
          yob={ yob } setYob={ setYob }
          addPoints={ addPoints } setAddPoints={ setAddPoints }
          totals={ totals }
        />
      </Section>
      <Section width="narrow" >
        <EditProgramPath
          program={ program } chooseProgram={ chooseProgram }
        />
      </Section>

      <SubjectGrades
        subjectMap={ subjectMap }
        removeSubject={ removeSubject }
        chooseSubject={ chooseSubject }
        setScore={ setScore }
      />

      <Section width="narrow">
        <SubjectSearch
          addSubject={ addSubject }
        />
      </Section>

      <Section width="narrow">
        <ContinueToSelector
          numMatches={ numMatches }
          onClick={ showStudySelector }
        />
      </Section>

      <Section width="narrow">
        <ContinueToLogin onClick={ goToLogin } />
      </Section>
    </>
  );
}
