/**
 * Study requirements compiler frontend
 * @module compiler/study-requirements/frontend
 */

import '../helpers';


// Operators and other reserved words
export const logicOp = [
  'og', 'eller',
];

export const compOp = [
  '>=', '<=', '>', '<', '==', '!=',
];

const groups = [
  '(', ')',
];

// Language variables
const variables = [
  '[A-ZÆØÅ][A-ZÆØÅ0-9-]{1,8}',
  '[0-9]{1,2}',
];


// Compiler frontend


/**
 * Takes a language token, identifies it and returns metadata about it
 *
 * @param {str} str the token
 * @returns {object} the token with metadata
 */
function getToken(str) {
  if (logicOp.includes(str)) {
    return {
      type: 'OP',
      value: str,
      prio: str === 'og' ? 4 : 5,   // AND: 4, OR: 5
    };
  }
  if (compOp.includes(str)) {
    return {
      type: 'COMP',
      value: str,
      prio: 3,
    };
  }
  if (groups.includes(str)) {
    return {
      type: 'GRP',
      value: str,
      prio: 2,
    };
  }
  for (const exp of variables) {
    if (str.match(new RegExp(`^${exp}$`, 'ig'))) {
      const value = parseInt(str);
      return {
        type: 'VAR',
        value: !isNaN(value) ? value : str,
        prio: 1,
      };
    }
  }
  return {
    type: null,
    value: str,
  };
}


/**
 * Breaks down the program code into a set of tokens
 *
 * @param {string} code the program code
 * @returns {array} the list of tokens
 */
export function tokenizer(code) {
  const escaped = [...logicOp, ...compOp, ...groups].map(op => op.replace(/([().])/g, '\\$1'));
  const lang = [...escaped, ...variables].map(k => `(${k})`).join('|');

  const regex = new RegExp(lang, 'gi');
  const tokens = code.split(regex).map(t => t && t.trim()).filter(t => t);

  return tokens.map(getToken);
}


/**
 * Cleans up a node before returning it.
 * Removes unnecessary and circular attributes.
 *
 * @param {object} node the tree node
 * @returns {object} the clean node
 */
function cleanTree(node) {
  if (node) {
    delete node.prio;
    delete node.parent;
    if (node.children) {
      node.children = node.children && node.children.map(child => cleanTree(child));
    }
  }
  return node;
}


/**
 * Takes a list of tokens and generates a binary parse tree
 * according to the language rules.
 *
 * @param {array} tokens the list of tokens
 * @returns {object} the parser tree
 */
export function parser(tokens) {
  let cur;

  tokens.forEach((token, i) => {
    const next = tokens[i + 1];

    if (!token.type) {
      throw new Error(`Unkown token: ${token.value}`);
    }

    // Variables
    if (token.type === 'VAR') {
      const node = {
        type: 'VAR',
        value: token.value,
      };

      if (cur) {
        if (cur.type === 'VAR') {
          throw new Error('Two vars in a row');
        }
        cur.children.push(node);
        node.parent = cur;
      }
      cur = node;
    }

    // Groups
    if (token.type === 'GRP') {
      if (token.value === '(') { // open
        const node = {
          type: 'GRP',
          children: [],
          closed: false,
        };

        if (cur) {
          if (cur.type !== 'OP' && cur.type !== 'COMP') {
            throw new Error('Invalid operator before group');
          }
          cur.children.push(node);
          node.parent = cur;
        }
        cur = node;
      }
      else {  // close
        while (cur.parent && cur.type !== 'GRP') {
          cur = cur.parent;
        }
        // if(!cur || cur.type !== 'GRP') {
        //   throw new Error('Open parentheses');
        // }
        cur.closed = true;
      }
    }


    // Comparison operator
    if (token.type === 'COMP') {
      if (!next || (next.type !== 'VAR' && next.type !== 'GRP')) {
        throw new Error('Lonely comparison (after)');
      }
      if (!cur || (cur.type !== 'VAR' && cur.type !== 'GRP')) {
        throw new Error('Lonely comparison (before)');
      }

      const node = {
        type: 'COMP',
        value: token.value,
        children: [],
        prio: token.prio,
      };

      if (cur.parent) {
        if (cur.parent.type === 'COMP') {
          throw new Error('Invalid comparison sequence');
        }
        cur.parent.children.pull(cur);
        cur.parent.children.push(node);
        node.parent = cur.parent;
      }
      node.children.push(cur);
      cur.parent = node;
      cur = node;
    }


    // Logical operator
    if (token.type === 'OP') {
      if (!next || !cur) {
        throw new Error('Lonely operator');
      }
      if (cur.type === 'OP' || next.type === 'OP') {
        throw new Error('Two sequential operators');
      }

      const node = {
        type: 'OP',
        value: token.value,
        children: [],
        prio: token.prio,
      };

      // Jump up if parent node is an operator (with lower priority)
      while (cur.parent && cur.parent.prio <= node.prio) {
        cur = cur.parent;
      }
      if (cur.parent) {
        cur.parent.children.pull(cur);
        cur.parent.children.push(node);
        node.parent = cur.parent;
      }
      node.children.push(cur);
      cur.parent = node;
      cur = node;
    }
  });

  while (cur.parent) {
    cur = cur.parent;
  }
  return cleanTree(cur);
}


/**
 * Compiles the requirement expressions and generates a parser tree for each requirement
 *
 * @param {object} data an object containing the expressions to be compiled
 * @returns {object} the input object with an added parser tree
 */
export function compile(data) {
  const res = {};
  Object.keys(data).forEach(req => {
    try {
      const logic = data[req].logic;
      res[req] = logic ? parser(tokenizer(data[req].logic)) : null;
    }
    catch (err) {
      console.log('>> Error parsing rule', req);
      console.error(err);
      res[req] = null;
    }
  });
  return res;
}
