import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@utdanningno/designsystem-react';


export default function ContinueToSelector({ numMatches, onClick }) {
  return (
    <>
      <h2>Gå til studievelger</h2>
      <p>
        Se hvilke studier du kan komme inn på med dine poeng. Studievelger
        viser deg også hvilke fag du må ha på videregående for å komme inn på
        ulike studier.
      </p>
      <p>
        Basert på dine poeng finnes det
        <br />
        <strong>{numMatches}</strong> mulige studier.
      </p>
      <Button primary chevron onClick={ onClick }>Gå til studievelger</Button>
    </>
  );
}


ContinueToSelector.propTypes = {
  numMatches: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};
