import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as IconChevron } from '../../assets/chevron-down.svg';

import { Filter as FilterButton } from '@utdanningno/designsystem-react';

import './styles.scss';

export default function ExpandableField({
  label,
  labelOpen,
  open,
  children,
  alternative,
  className,
  ...props
}) {
  const [isOpen, setIsOpen] = useState(open || false);

  const handleToggle = () => setIsOpen(!isOpen);

  const classIsOpen = isOpen ? 'is-open' : '';
  const classAlt = alternative ? 'expandable-field--alt' : '';

  return (
    <div
      className={ ['expandable-field', classIsOpen, classAlt, className].join(' ').trim() }
      { ...props }
    >
      {!alternative ?
        <FilterButton
          onClick={ handleToggle }
          isSelected={ isOpen }
          defaultLabel={ label }
          selectedLabel={ labelOpen }
        />
        :
        <button
          type="button"
          className="expandable-field__toggle"
          onClick={ handleToggle }>
          {isOpen && labelOpen ? labelOpen : label}
          <IconChevron className="icon-chevron" aria-hidden />
        </button>}
      {isOpen &&
        <div className="expandable-field__content">
          {children}
        </div>
      }
    </div>
  );
}

ExpandableField.propTypes = {
  label: PropTypes.string.isRequired,
  labelOpen: PropTypes.string,
  open: PropTypes.bool,
  alternative: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
  ]),
};
