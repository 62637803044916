import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';

import Section from '../../section';
import LinkPanel from '../../link-panel';
import EmptyLinkPanel from '../../link-panel/empty';
import Breadcrumb from '../breadcrumb';
import Breadbutton from '../breadcrumb/button';

import { abcSort, filterKey, slugify, toElem } from '../../../utils/helpers';
import { allSections } from '../../../utils/studies';
import { Checkbox } from '@utdanningno/designsystem-react';

import './styles.scss';


export default function CategoryList({
  sections = {},
  matches,
  counts,
  showOnlyMatches,
  setShowOnlyMatches,
  loading,
}) {

  const routeMatch = useRouteMatch();
  const [interestName, setInterestName] = useState('');
  const [list, setList] = useState([]);


  useEffect(() => {
    const { interest } = routeMatch.params;

    if (interest) {
      // 2nd level -> Show list of categories
      const intName = filterKey(allSections, interest);
      setInterestName(intName);

      const newList = sections[intName] ? Object.keys(sections[intName]).sort(abcSort) : [];
      setList(newList);
    }
    else {
      // 1st level -> Show list of interests
      setInterestName('');

      const newList = Object.keys(sections).sort(abcSort);
      setList(newList);
    }
  },
  [routeMatch.params, sections]);


  // Determine matches and totals
  const pageMatches = interestName ? matches[interestName]?.$ || 0 : matches.$;
  const pageCounts = interestName ? counts[interestName]?.$ || 0 : counts.$;

  const getMatch = name => interestName ? matches[interestName]?.[name] || 0 : matches[name]?.$ || 0;
  const getCount = name => interestName ? counts[interestName]?.[name] || 0 : counts[name]?.$ || 0;

  // Link prefix
  const prefix = interestName ? `${slugify(interestName)}/` : '';


  const onClick = ev => {
    toElem('selector-content');
    return ev.target.parentElement?.blur();
  };


  return (
    <Section>
      {interestName &&
        <>
          <Breadcrumb className="mt-xl" match={ routeMatch } />
          <Breadbutton className="mt-sm" match={ routeMatch } />
        </>
      }

      <Checkbox
        label="Vis kun studier jeg sannsynligvis kommer inn på"
        name="show-only-availabe-1"
        onChange={ e => {
          setShowOnlyMatches(e.target.checked);
        } }

      />

      <p className="mt-sm text-lg">
        <span className="font-bold">{pageMatches}</span>
        {!showOnlyMatches ? <span> av <span className="font-bold">{pageCounts}</span></span> : null}
        <span> studier</span>
      </p>

      <div className="category-list mt-lg">
        <h2 className="category-list__title">
          {interestName || 'Interesser'}
        </h2>

        <ul className="list-reset">
          {list.map((name, idx) => {
            const m = getMatch(name), c = getCount(name);

            return (m || !showOnlyMatches)
              ? <li className="category-list__item" key={ idx } >
                <LinkPanel
                  title={ name }
                  to={ `/studievelger/${prefix}${slugify(name)}` }
                  studies={ showOnlyMatches ? [m] : [m, c] }
                  onClick={ onClick }
                  large
                />
              </li>
              : null;
          })}
        </ul>

        {loading && <p className="mt-lg">Henter studier...</p>}
        {!loading && (!pageCounts || (showOnlyMatches && !pageMatches)) && <EmptyLinkPanel />}
      </div>
    </Section>
  );
}
