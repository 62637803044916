import React from 'react';


function ScoreRange({ points, setPoints }) {

  const onScoreChange = ev => {
    const newPoints = { ...points };
    newPoints.school = parseInt(ev.target.value) || 0;
    newPoints.total = newPoints.school + (newPoints.age || 0) + (newPoints.extra || 0);
    setPoints(newPoints);
  };

  return (
    <div>
      <h2>Skolepoeng: {points.school} / Konk.poeng: {points.total}</h2>
      <input
        type="range" min="0" max="64" step="1"
        value={ points.school || 0 }
        onChange={ onScoreChange }
        style={ { maxWidth: 400 } }
      />
    </div>
  );
}

export default ScoreRange;
