import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import MainNav from './main-nav';
import GradeCalculator from './grade-calculator';
import StudySelector from './study-selector';


function Prototype({ state1, state2, actions }) {
  return (
    <div>
      <MainNav />
      <Switch>
        <Route exact path="/proto/grade-calculator">
          <GradeCalculator { ...state1 } { ...actions } />
        </Route>
        <Route path="/proto/study-selector">
          <StudySelector { ...state2 } />
        </Route>
        <Redirect to="/proto/grade-calculator" />
      </Switch>
    </div>
  );
}

export default Prototype;
