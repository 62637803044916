import React, { useEffect, useState } from 'react';


let ts;

function StudySearch({ searchStr, setSearchStr }) {
  const [q, setQ] = useState('');

  useEffect(() => {
    setQ(searchStr);
  },
  [searchStr]);

  const onTextChange = ev => {
    const value = ev.target.value;
    setQ(value);

    clearTimeout(ts);
    ts = setTimeout(() => setSearchStr(value), 500);
  };

  return (
    <section>
      <h2>Søk i studievelger:</h2>
      <div><input type="text" value={ q } onChange={ onTextChange } /></div>
    </section>
  );
}

export default StudySearch;
