import React from 'react';

import { ReactComponent as IconQuestionCircle } from '../../assets/info-circle.svg';

import './styles.scss';


export default function EmptyLinkPanel({ className }) {
  return (
    <div className={ ['link-panel-empty', className].join(' ').trim() }>
      <div className="link-panel-empty__name">
        <IconQuestionCircle className="mr-xs infobox__icon" aria-label="Info" />
        Obs!
      </div>
      <div className="link-panel-empty__content">
        Søkekriteriene dine ga ingen resultater.
      </div>
    </div>
  );
}
