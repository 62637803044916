import React from "react";
import PropTypes from 'prop-types';
import Plus from '../../assets/plus.svg';

/**
 *
 * @param isSelected
 * @param defaultLabel
 * @param selectedLabel
 * @param className
 * @param labelStyles
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */

export function Filter({isSelected, defaultLabel, selectedLabel, className, labelStyles, ...rest}) {
  const label = isSelected ? selectedLabel : defaultLabel;
  const classes = [
    `filter`,
    isSelected ? `filter--selected` : ``,
    className
  ].join(` `);

  return (
    <button className={classes} {...rest} aria-pressed={isSelected}>
      <i className={'filter__icon'}><Plus/></i>
      <span className={'filter__label'} style={labelStyles}>{label}</span>
    </button>
  );
}

Filter.propTypes = {
  isSelected: PropTypes.bool,
  defaultLabel: PropTypes.string,
  selectedLabel: PropTypes.string,
  labelStyles: PropTypes.object,
};

Filter.defaultProps = {
  isSelected: undefined,
  defaultLabel: undefined,
  selectedLabel: undefined,
  labelStyles: null,
};

export default Filter;
