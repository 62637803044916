import 'react-app-polyfill/ie11';
// import * as Sentry from '@sentry/react';
// import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import { createRoot } from 'react-dom/client';

import './sass/style.scss';
import App from './pages';

// Sentry.init({
//   dsn: 'https://243c9b0558db4c8193813ddff0be2b48@o507370.ingest.sentry.io/4504117425471488',
//   integrations: [new BrowserTracing()],
//
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

// Add class 'mouse-click' to document body to prevent :focus style to display on click
document.body.addEventListener('mousedown', () => {
  document.body.classList.add('mouse-click');
});

// Remove class 'mouse-click' when keyboard 'Tab' is used
document.body.addEventListener('keydown', e => {
  if (e.key === 'Tab') {
    document.body.classList.remove('mouse-click');
  }
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(<React.StrictMode><App /></React.StrictMode>);
