import ReactTooltip from 'react-tooltip';
import TooltipButton from "../../assets/tooltip_button.svg";
import TooltipInfo from "../../assets/tooltip_info.svg";
import TooltipClose from "../../assets/tooltip_close.svg";
import ReactDOMServer from "react-dom/server";
import React, {useRef, useState} from "react";
import PropTypes from "prop-types";

export function Tooltip({id, heading, content, closeLabel, className}) {
  const classes = [
    'tooltip',
    className
  ].join(` `);
  const closeClasses = [
    'tooltip__close',
    'js-tooltip-close'
  ].join(` `);

  const [isLoaded, setIsLoaded] = useState(false);

  const HTML = ReactDOMServer.renderToString(
    <>
      {
        (heading || content) &&
        <>
          <div className={'tooltip__heading'}>
            {
              heading &&
              <>
                <TooltipInfo aria-hidden={true} className={'tooltip__info-icon'}/>
                <strong>{heading}</strong>
              </>
            }
            <TooltipClose className={closeClasses} role="button" aria-label={closeLabel}/>
          </div>
          {
            content &&
            <div className={'tooltip__content'}>
              {content}
            </div>
          }
        </>
      }
    </>
  );

  /**
   * handles custom close button within tooltip popup
   */
  const handleAfterShow = () => {
    if (!isLoaded) {
      wrapperRef?.current?.querySelector('.js-tooltip-close')?.addEventListener('click', function () {
        ReactTooltip.hide(tooltipRef?.current);
        wrapperRef?.current?.querySelector('.show').classList.remove('show');
      }, false);
      setIsLoaded(true);
    }
  }

  const wrapperRef = useRef(null);
  const tooltipRef = useRef(null);
  const descriptionID = `${id}-description`;
  const descriptionText = `${heading}. ${content}`

  return (
    <div className={'tooltip__wrapper'} ref={wrapperRef}>
      <span id={descriptionID} className={'tooltip__description'} aria-hidden="false">{descriptionText}</span>
      <div
        data-for={id}
        data-tip={HTML}
        ref={tooltipRef}
      >
        <TooltipButton
          tabIndex="0"
          role="button"
          className={'tooltip__button'}
        />
      </div>

      <ReactTooltip
        id={id}
        place='bottom'
        event='click'
        effect='solid'
        className={classes}
        html={true}
        clickable={true}
        afterShow={handleAfterShow}
      />
    </div>
  );
}

Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
};


export default Tooltip;
