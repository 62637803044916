/**
 * Subject requirements compiler frontend
 * @module compiler/subject-requirements/backend
 */


// Patterns for identifying variables
const SUBJECT_NAME = /^[A-ZÆØÅ]{3,5}[0-9]{2,4}$/g;
const SUBJECT_PATTERN = /^[A-ZÆØÅ]{3,5}x{2,4}/g;


// Compiler backend


/**
 * Reflects the schoolPoints property from the input data
 *
 * @param {object} input the program input (it should contain a schoolPoints property)
 * @returns {number} the total score
 */
function getSchoolPoints({ schoolPoints }) {
  return schoolPoints;
}


/**
 * Returns the subject scores (position + exam) for a group of subjects that match a given pattern
 *
 * @param {object} input the program input (it should contain a subjectScores property)
 * @param {string} pattern the pattern for the subject id
 * @returns {number[]|false} a list of scores or false if there are no scores
 */
function getSubjectScoresByPattern({ subjectScores }, pattern) {
  const exp = pattern.replace(/[x]+/g, '.+');
  const regExp = new RegExp(`^${exp}$`, 'g');

  const res = [];
  Object.keys(subjectScores).forEach(name => {
    if (name.match(regExp) && subjectScores[name]) {
      res.push(...subjectScores[name].filter(s => s && s > 1));
    }
  });
  return res.length ? res : false;
}


/**
 * Returns the subject scores (position + exam) or false if there are no valid scores
 *
 * @param {object} input the program input (it should contain a subjectScores property)
 * @param {string} name the id of the subject
 * @returns {number[]|false} a list of scores or false if there are no scores
 */
function getSubjectScore({ subjectScores }, name) {
  const res = subjectScores[name]?.filter(s => s && s > 1);
  return res && res.length ? res : false;
}


/**
 * Calculates the average score given a list of scores
 *
 * @param {number[]} list the list of scores
 * @returns {number} the average value of that list
 */
function getScoreAverage(list) {
  if (!list || !list.length) {
    return 0;
  }
  const sum = list.reduce((a, b) => a + b, 0);
  return (sum / list.length);
}


/**
 * Combines the subject scores from the 2 branches
 *
 * @param {number[]} left left branch scores
 * @param {number[]} right right branch scores
 * @returns {number[]} the combined scores list
 */
function joinScores(left, right) {
  if (left === true) {
    return right;
  }
  if (right === true) {
    return left;
  }
  return [...left, ...right];
}


/**
 * Generates an executable program for a give tree.
 * The return function will be specific for this language.
 *
 * @param {object} tree the program parser tree
 * @returns {function} the executable function
 *
 */
export function generator(tree) {
  /**
   * Evalutes the result for a node given an input
   *
   * @inner
   * @param {object} node the node to be evaluatred
   * @param {object} input the input data for the program
   * @returns {array} the result of the evaluation
   */
  function evaluate(node, input) {
    const { type, value, children, negate } = node;

    if (type === 'OP') {
      const a = evaluate(children[0], input);
      const b = evaluate(children[1], input);

      if (value === 'eller') {
        return a || b;
      }
      if (value === 'ellerf') {
        return a && b ? joinScores(a, b) : a || b;
      }
      if (value === 'og' || value === 'ogf') {
        return a && b ? joinScores(a, b) : false;
      }
    }

    if (type === 'GRP') {
      const res = evaluate(children[0], input);
      if (value === 'average') {
        return getScoreAverage(res);
      }
      return res;
    }

    if (type === 'VAR') {
      if (value.match(SUBJECT_NAME)) {
        const res = getSubjectScore(input, value);
        return negate ? !res : res;
      }
      if (value.match(SUBJECT_PATTERN)) {
        return getSubjectScoresByPattern(input, value);
      }
      if (value === 'SKOLEPOENG') {
        return getSchoolPoints(input);
      }
      if (value === '1=1' || value.startsWith('omfang')) {
        return true;
      }
      return false;
    }
  }

  return function (input) {
    if (!tree) {
      return false;
    }
    const res = evaluate(tree, input);
    return typeof res === 'number' ? res : !!res;
  };
}
