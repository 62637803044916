import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as PlusCircle } from '../../../assets/plus.svg';

import './result.scss';


export default function SearchResult({ subject, onSubjectChoice }) {
  const { name, id } = subject;

  return (
    <button
      type="button"
      className="add-subject-search-result"
      onClick={ () => onSubjectChoice(subject) }
    >
      <h3 className="h2 name">{name}</h3>
      <div className="row">
        <span className="text-sm">Fagkode: [{id}]</span>
        <span className="fake-button">Legg til fag <PlusCircle /></span>
      </div>
    </button>
  );
};


SearchResult.propTypes = {
  subject: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
  onSubjectChoice: PropTypes.func,
};

SearchResult.defaultProps = {
  subject: {},
  onSubjectChoice: () => { },
};
