import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { abcSort, filterKey, slugify, toTop } from '../../../utils/helpers';


function SectionList({ matches, counts, sections, match }) {

  const [interestName, setInterestName] = useState('');
  const [list, setList] = useState([]);

  useEffect(() => {
    const { interest } = match.params;

    if (interest) {
      // 2nd level -> Show list of categories
      const intName = filterKey(sections, interest);
      setInterestName(intName);

      const newList = Object.keys(sections[intName]).sort(abcSort);
      setList(newList);
    }
    else {
      // 1st level -> Show list of interests
      setInterestName('');

      const newList = Object.keys(sections).sort(abcSort);
      setList(newList);
    }
  },
  [match.params, sections]);


  // Determine matches and totals
  const pageMatches = interestName ? matches[interestName]?.$ || 0 : matches.$;
  const pageCounts = interestName ? counts[interestName]?.$ || 0 : counts.$;

  const getMatch = name => interestName ? matches[interestName]?.[name] || 0 : matches[name]?.$ || 0;
  const getCount = name => interestName ? counts[interestName]?.[name] || 0 : counts[name]?.$ || 0;

  // Link prefix
  const prefix = interestName ? `${slugify(interestName)}/` : '';


  return (
    <div>
      <h2><u>{interestName || 'Studiekategorier'}</u></h2>
      <span>{pageMatches} av {pageCounts} mulige studier</span>

      <ul>
        {list.map((name, i) => (
          <li key={ i }>
            <h3>
              <Link to={ `/proto/study-selector/${prefix}${slugify(name)}` } onClick={ toTop }>
                <span>{name}</span>
              </Link>
            </h3>
            <span>({getMatch(name)} av {getCount(name)} mulige studier)</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default SectionList;
