import React, { useEffect, useRef, useState } from 'react';
import { Route, Switch } from 'react-router';

import Section from '../../components/section';
import CategoryList from '../../components/selector/category-list';
import StudyList from '../../components/selector/study-list';
import Filter from '../../components/selector/filter';
import ScoreboardMini from '../../components/scoreboard-mini';
import EditPoints from '../../components/edit-points';

import {
  calculateRequirements,
  getDescribedRequirements,
} from '../../utils/subject-logic';
import { getStudyRequirements } from '../../utils/study-logic';
import { getStudies } from '../../utils/studies';
import { isYobOver22 } from '../../utils/helpers';
import { Button } from '@utdanningno/designsystem-react';

let tsReq;
let tsList;

function StudySelector({ subjectMap = {}, totals = {}, yob, showFAQ }) {
  const ref = useRef();
  useEffect(() => {
    ref.current = true;
    return () => (ref.current = false);
  }, []);

  const [showOnlyMatches, setShowOnlyMatches] = useState(false);
  const [loading, setLoading] = useState(false);

  const [searchStr, setSearchStr] = useState('');
  const [searchFilter, setSearchFilter] = useState({});

  const [studyPoints, setStudyPoints] = useState({});
  const [requirements, setRequirements] = useState({
    calculated: {},
    described: {},
    study: {},
  });

  const resetPoints = () => {
    setStudyPoints(totals);
  };
  useEffect(resetPoints, [totals]);

  useEffect(() => {
    // Recalculate the requirements
    clearTimeout(tsReq);
    tsReq = setTimeout(() => {
      const calcReqs = calculateRequirements(subjectMap, studyPoints);
      const descReqs = getDescribedRequirements();
      const studyReqs = getStudyRequirements(calcReqs, descReqs);
      if (ref.current) {
        setRequirements({
          calculated: calcReqs,
          described: descReqs,
          study: studyReqs,
        });
      }
    }, 250);
  }, [subjectMap, studyPoints]);

  const [sections, setSections] = useState({});
  const [matches, setMatches] = useState({ $: 0 });
  const [counts, setCounts] = useState({ $: 0 });

  useEffect(() => {
    setLoading(true);

    clearTimeout(tsList);
    tsList = setTimeout(() => {
      // Retrieve study sections, with counts and matches
      const newStudies = getStudies(
        searchStr,
        searchFilter,
        requirements.study,
        studyPoints
      );
      if (ref.current) {
        setSections(newStudies.sections);
        setMatches(newStudies.matches);
        setCounts(newStudies.counts);
        setLoading(false);
      }
    }, 250);
  }, [searchStr, searchFilter, requirements.study, studyPoints]);

  const filterProps = {
    match: matches.$ || 0,
    count: counts.$ || 0,
    searchStr,
    setSearchStr,
    searchFilter,
    setSearchFilter,
    showOnlyMatches,
    setShowOnlyMatches,
  };

  const listProps = {
    sections,
    matches,
    counts,
    showOnlyMatches,
    setShowOnlyMatches,
    loading,
  };

  const isOver22 = isYobOver22(yob);

  return (
    <div className="page-layout">
      <div className="page-layout__main" aria-label="Hovedinnhold">
        <Section width="wide">
          <h1>Studievelger</h1>
          <p>
            Her kan du se hvilke utdanninger du kan komme inn på i ulike
            studiekategorier, med dine fag og poeng.
          </p>
          <p>
            Poenggrensene som vises er fra forrige opptak, og sier noe om hvor
            vanskelig det er å komme inn på en utdanning. Poenggrensene vil
            variere fra år til år, og i tabellen ser du hvordan poenggrensene
            har endret seg over tid. Neste års poenggrenser oppdateres når
            opptaket er gjennomført.
          </p>
          <p className="h3">Vet du ikke snittet ditt?</p>
          <Button href="/karakterkalkulator" chevron>
            Gå til Karakterkalkulatoren
          </Button>
        </Section>

        <Section className="lg-down" width="wide">
          <EditPoints
            points={ studyPoints }
            setPoints={ setStudyPoints }
            resetPoints={ resetPoints }
            above22={ isOver22 }
            compact
          />
          <ScoreboardMini
            className="mt-lg"
            points={ studyPoints }
            above22={ isOver22 }
          />
        </Section>

        <Section width="wide">
          <Filter { ...filterProps } />
        </Section>

        <section id="selector-content">
          <Switch>
            <Route exact path="/studievelger/:interest/:category">
              <StudyList
                { ...listProps }
                requirements={ requirements }
                points={ studyPoints }
                above22={ isOver22 }
              />
            </Route>
            <Route exact path="/studievelger/:interest?">
              <CategoryList { ...listProps } />
            </Route>
          </Switch>
        </section>
      </div>
      <div className="page-layout__aside" aria-label="Skolepoeng">
        <Section>
          <ScoreboardMini
            className="lg-up"
            points={ studyPoints }
            above22={ isOver22 }
          />
          <EditPoints
            className="mt-xl lg-up"
            points={ studyPoints }
            setPoints={ setStudyPoints }
            resetPoints={ resetPoints }
            above22={ isOver22 }
          />
        </Section>
      </div>
    </div>
  );
}

export default StudySelector;
